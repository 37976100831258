// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Card from '../components/Card';
import SectionTitle from '../components/SectionTitle';
import Button from '../components/Button';

// Utils
import { getBlogPost } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';
import getLocalizedPath from '../utils/getLocalizedPath';

class BlogSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      category: {
        name: '',
        slug: '',
      },
      content: '',
      date: null,
      metatags: {},
      redirect: null,
      relatedPosts: [],
      slug: props.match.params.slug,
      thumbnail: {
        title: '',
        sizes: {
          landscapeSm: '',
          landscapeWideLg: '',
        },
      },
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getBlogPost(slug);

    if (this._isMounted && !!data) {
      const {
        category,
        content,
        date,
        metatags,
        relatedPosts,
        thumbnail,
        title,
      } = data;

      this.setState(prevState => ({
        ...prevState,
        category,
        content,
        date,
        metatags,
        relatedPosts,
        thumbnail,
        title,
      }));
    } else {
      // Page isn't published or slug does not exist, redirect to parent
      const {
        match: { path },
      } = this.props;

      this.setState({
        redirect: path.replace('/:slug', ''),
      });
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      category,
      content,
      date,
      metatags,
      redirect,
      relatedPosts,
      thumbnail,
      title,
    } = this.state;

    const {
      match: { path },
    } = this.props;

    const pathname = path.replace('/:slug', '');

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    return (
      <Layout className="blog-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <p className="date">
                  {date} - {category.name}
                </p>
                <h1 className="hero__title">{title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-page__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                {!!thumbnail.sizes.landscapeSm && (
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={thumbnail.sizes.landscapeWideLg}
                    />
                    <img
                      src={thumbnail.sizes.landscapeSm}
                      alt={thumbnail.title}
                      title={thumbnail.title}
                    />
                  </picture>
                )}
                <div className="blog-page__content--text gutenberg-wrapper">
                  {renderHtml(content)}
                </div>
                <div className="blog-page__content--cta">
                  <Button to={getLocalizedPath('blog')}>
                    {i18n.t('back')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {!!relatedPosts.length && (
          <>
            <SectionTitle centered={false} title={i18n.t('blog').also_like} />
            <section className="blog-related">
              <div className="container">
                <div className="row">
                  {relatedPosts.map(relatedPost => {
                    return (
                      <Card
                        key={relatedPost.id}
                        title={relatedPost.title}
                        image={{
                          sizes: {
                            medium: relatedPost.thumbnail.sizes.landscapeWideMd,
                            small: relatedPost.thumbnail.sizes.landscapeWideSm,
                          },
                          title: relatedPost.thumbnail.title,
                        }}
                        caption={`${relatedPost.date} - ${relatedPost.category.name}`}
                        slug={relatedPost.slug}
                        href={`${pathname}/${relatedPost.slug}`}
                        hover={i18n.t('see_more')}
                      />
                    );
                  })}
                </div>
              </div>
            </section>
          </>
        )}
      </Layout>
    );
  }
}

BlogSingle.contextType = AppContext;

export default withTranslation()(BlogSingle);
