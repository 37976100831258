// vendors
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// i18n
import i18n from '../../i18n';

// Utils
import { capitalize } from '../../utils/tools';

// Styles
import './_dropdownCat.scss';

class DropdownCat extends Component {
  constructor(props) {
    super(props);

    const { id, selected  } = this.props;

    this.state = {
      DropdownCatElement: null,
      id,
      open: false,
      resetted: false,
      selected: selected
        ? options.find(option => option[0].slug === selected).name
        : null,
    };

    this.toggleList = this.toggleList.bind(this);
    this.updateList = this.updateList.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    const DropdownCatElement = document.getElementById(id);

    this.setState({
      DropdownCatElement,
    });
  }

  componentDidUpdate() {
    const { reset } = this.props;
    const { resetted } = this.state;

    if (reset && !resetted) {
      this.setState({
        open: false,
        resetted: true,
        selected: null,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  toggleList() {
    const { open } = this.state;

    if (!open) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({
      open: !open,
    });
  }

  updateList(slug) {
    const { options, type, onClick } = this.props;

    document.removeEventListener('click', this.handleOutsideClick, false);

    this.setState({
      resetted: false,
      selected: slug ? options.find(option => option[0].slug === slug).name : null,
    });

    onClick(type, slug);
    this.toggleList();
  }

  handleOutsideClick(e) {
    const { DropdownCatElement } = this.state;
    const { target } = e;

    // ignore clicks on the component itself
    if (DropdownCatElement.contains(target)) {
      return;
    }

    this.toggleList();
  }

  render() {
    const {
      disabled,
      label,
      options,
      placeholder,
      small,
      withAll,
    } = this.props;
    const { id, open, selected } = this.state;
    const uniqueKeys = new Set();

    // console.log(JSON.stringify(options));

    let toggleList = this.toggleList;

    if (disabled) {
      toggleList = () => {};
    }

    let dropdownClasses = 'dropdown';
    if (open) dropdownClasses += ' dropdown--open';
    if (small) dropdownClasses += ' dropdown--small';
    if (disabled) dropdownClasses += ' dropdown--disabled';

    return (
      <div id={id} className={dropdownClasses}>
        {!!label && (
          <label htmlFor={id} className="form-label dropdown__label">
            {label}
          </label>
        )}
        <span
          onClick={toggleList}
          onKeyDown={toggleList}
          role="button"
          tabIndex={0}
        >
          {!selected && placeholder}
          {selected && capitalize(selected)}
          <span className="dropdown__arrow" />
        </span>
        {!disabled && (
          <ul className="dropdown__list">
            {!!withAll && (
              <li>
                <span
                  onClick={() => {
                    this.updateList(null);
                  }}
                  onKeyDown={() => {
                    this.updateList(null);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {i18n.t('all')}
                </span>
              </li>
            )}

            {options.map(item => {
              item = item[0];
              if (uniqueKeys.has(item.term_id)) {
                return null;
              }
              uniqueKeys.add(item.term_id);
              return (
                <li key={`cat_${item.slug}`}>
                  <span
                    onClick={() => {
                      this.updateList(item.slug);
                    }}
                    onKeyDown={() => {
                      this.updateList(item.slug);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {item.name}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

DropdownCat.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    )
  ).isRequired,
  placeholder: PropTypes.string,
  reset: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  small: PropTypes.bool,
  type: PropTypes.string.isRequired,
  withAll: PropTypes.bool,
};

DropdownCat.defaultProps = {
  disabled: false,
  label: null,
  placeholder: '',
  reset: false,
  selected: null,
  small: false,
  withAll: true,
};

export default DropdownCat;
