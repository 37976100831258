import fetch from 'node-fetch';

/*
 * Get Wordpress Data
 */
export async function getWordpressData(
  endpoint = null,
  root = process.env.WORDPRESS_REST_API_URL
) {
  if (endpoint === null) return false;

  try {
    const response = await fetch(root + endpoint);
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}

/*
 * Post Wordpress Data
 */
export async function postWordpressData(endpoint = null, datas = []) {
  if (endpoint === null) return false;

  try {
    const response = await fetch(
      process.env.WORDPRESS_REST_API_URL + endpoint,
      {
        method: 'POST',
        body: datas,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}

/*
 * Page
 */
export async function getPage(page, type = 'slug') {
  let response = false;

  if (type === 'slug') {
    response = await getWordpressData(`wp/v2/pages?slug=${page}`);
    response = response[0];
  } else if (type === 'id') {
    response = await getWordpressData(`wp/v2/pages/${page}`);
  }

  return response;
}

/*
 * Homepage
 */
export async function getHomepage() {
  const response = await getWordpressData('imedia/v2/homepage');
  return response;
}

/*
 * Option Page
 */
export async function getOptionPage(id = null) {
  if (id === null) return false;

  const response = await getWordpressData(`acf/v3/options/${id}`);
  return response;
}

/*
 * Restaurants
 */
export async function getRestaurants() {
  const response = await getWordpressData('imedia/v2/restaurants');
  return response;
}

/*
 * Restaurants catégories
 */
export async function getRestaurantCat(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/restaurant_category/${slug}`);
  return response;
}

/*
 * Single Restaurant
 */
export async function getRestaurant(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/restaurant/${slug}`);
  return response;
}

/*
 * Artisans
 */
export async function getArtisans() {
  const response = await getWordpressData('imedia/v2/artisans');
  return response;
}

/*
 * Single Restaurant
 */
export async function getArtisan(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/artisan/${slug}`);
  return response;
}

/*
 * Café
 */
export async function getCafe() {
  const response = await getWordpressData(`imedia/v2/cafe`);
  return response;
}

/*
 * Bar
 */
export async function getBar() {
  const response = await getWordpressData(`imedia/v2/bar`);
  return response;
}

/*
 * Blog Posts
 */
export async function getBlogPosts() {
  const response = await getWordpressData('imedia/v2/posts');
  return response;
}

/*
 * Single Blog Post
 */
export async function getBlogPost(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/post/${slug}`);
  return response;
}

/*
 * Events
 */
export async function getEvents() {
  const response = await getWordpressData('imedia/v2/events');
  return response;
}

/*
 * Single Event
 */
export async function getEvent(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/event/${slug}`);
  return response;
}

/*
 * Promotions
 */
export async function getPromotions() {
  const response = await getWordpressData('imedia/v2/promotions');
  return response;
}

/*
 * Single Promotion
 */
export async function getPromotion(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/promotion/${slug}`);
  return response;
}

/*
 * Contests
 */
export async function getContests() {
  const response = await getWordpressData('imedia/v2/contests');
  return response;
}

/*
 * Single Contest
 */
export async function getContest(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/contest/${slug}`);
  return response;
}

/*
 * Jobs
 */
export async function getJobs() {
  const response = await getWordpressData(
    'imedia/v2/jobs',
    process.env.QG_WORDPRESS_REST_API_URL
  );
  return response;
}

/*
 * Single Contest
 */
export async function getJob(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(
    `imedia/v2/job/${slug}`,
    process.env.QG_WORDPRESS_REST_API_URL
  );
  return response;
}

/*
 * Catering
 */
export async function getCatering() {
  const response = await getWordpressData('imedia/v2/catering');
  return response;
}

/*
 * About
 */
export async function getAbout() {
  const response = await getWordpressData('imedia/v2/about');
  return response;
}

/*
 * FAQ
 */
export async function getFaq() {
  const response = await getWordpressData('imedia/v2/faq');
  return response;
}

/*
 * StandardPage
 */
export async function getStandardPage(slug = null) {
  if (slug === null) return false;

  const response = await getWordpressData(`imedia/v2/standard-page/${slug}`);
  return response;
}

/**
 * Menu
 */
export async function getMenu() {
  const response = await getWordpressData('imedia/v2/menu');
  return response;
}

/*
 * Contact
 */
export async function getContact() {
  const response = await getWordpressData('imedia/v2/contact');
  return response;
}

/*
 * Contact form
 */
export async function postContactForm(id, datas = []) {
  if (id === null) return false;

  const response = await postWordpressData(
    `contact-form-7/v1/contact-forms/${id}/feedback`,
    datas
  );
  return response;
}

/*
 * Event Space
 */
export async function getEventSpace() {
  const response = await getWordpressData('imedia/v2/event-space');
  return response;
}

/*
 * Consent
 */
export async function getConsent() {
  const response = await getWordpressData('imedia/v2/consent');
  return response;
}

/*
 * VIP
 */
export async function getVip() {
  const response = await getWordpressData('imedia/v2/vip');
  return response;
}

/*
 * reCAPTCHA API
 */
export async function verifyReCAPTCHA(captcha) {
  try {
    const response = await fetch('/.netlify/functions/reCAPTCHA', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: captcha,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}
