// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AppContext } from '../../context';

// Components
import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import YoastSeo from '../YoastSeo';

// Styles
import '../../assets/scss/app.scss';

const Layout = ({ children, className, metatags }) => {
  const schemaOpeningHours = [];

  return (
    <AppContext.Consumer>
      {context => {
        const options = context.options ? context.options : {};

        options.schema.openingHours.map(opening => {
          schemaOpeningHours.push({
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [opening.days.join(',')],
            opens: opening.opens,
            closes: opening.closes,
          });
          return true;
        });

        return (
          <>
            {context.loading && <Loader />}
            <div className={className}>
              <YoastSeo tags={metatags} />

              <Header
                links={options.links}
                pathname={window.location.pathname}
              />
              {children}
              <Footer options={options} pathname={window.location.pathname} />

              <Helmet
                script={[
                  {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify({
                      '@context': 'https://schema.org',
                      '@type': 'LocalBusiness',
                      name: options.schema.name,
                      description: options.schema.description,
                      url: window.location.origin,
                      image: `${window.location.origin}/assets/img/district-gourmet.png`,
                      telephone: options.schema.telephone,
                      address: {
                        '@type': 'PostalAddress',
                        streetAddress: options.schema.streetAddress,
                        addressLocality: options.schema.addressLocality,
                        addressRegion: options.schema.addressRegion,
                        postalCode: options.schema.postalCode,
                        addressCountry: options.schema.addressCountry,
                      },
                      openingHoursSpecification: schemaOpeningHours,
                      sameAs: [
                        options.social.facebook,
                        options.social.instagram,
                      ],
                    }),
                  },
                ]}
              />
            </div>
          </>
        );
      }}
    </AppContext.Consumer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  metatags: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
};

Layout.defaultProps = {
  className: '',
  metatags: {},
};

export default Layout;
