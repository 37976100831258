// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import ContactForm from '../components/ContactForm';
import Layout from '../components/Layout';
import MapMarker from '../components/MapMarker';
import TextImageUnder from '../components/TextImageUnder';
import TextThreeColumns from '../components/TextThreeColumns';

// Utils
import { getContact } from '../utils/wordpressApi';
import { slugify } from '../utils/tools';

// Config
import googleMapStyles from '../config/googleMapStyles.json';

class Contact extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      focus: {
        image: {
          sizes: {
            desktop: '',
            mobile: '',
          },
          title: '',
        },
        text: '',
        title: '',
      },
      googleMap: {
        center: {
          lat: 46.7730808,
          lng: -71.2974232,
        },
        key: process.env.GOOGLE_MAP_API_KEY,
        marker: {
          infowindow: {
            text: '',
            title: '',
          },
          isOpen: false,
          lat: 46.7730808,
          lng: -71.2974232,
        },
        zoom: 16,
      },
      informations: [],
      metatags: {},
      title: '',
    };

    this.toggleInfowindow = this.toggleInfowindow.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getContact();

    if (this._isMounted && !!data) {
      const {
        contacts,
        focus,
        googleMap,
        informations,
        metatags,
        title,
      } = data;

      this.setState(prevState => ({
        ...prevState,
        contacts,
        focus,
        googleMap: {
          center: {
            lat: googleMap.lat,
            lng: googleMap.lng,
          },
          key: process.env.GOOGLE_MAP_API_KEY,
          marker: {
            infowindow: {
              text: googleMap.text,
              title: googleMap.title,
            },
            isOpen: false,
            lat: googleMap.lat,
            lng: googleMap.lng,
          },
          zoom: 16,
        },
        informations,
        metatags,
        title,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleInfowindow() {
    this.setState(prevState => ({
      ...prevState,
      googleMap: {
        ...prevState.googleMap,
        marker: {
          ...prevState.googleMap.marker,
          isOpen: prevState.googleMap.marker.isOpen ? false : true,
        },
      },
    }));
  }

  render() {
    const {
      contacts,
      focus,
      googleMap,
      informations,
      metatags,
      title,
    } = this.state;

    return (
      <Layout className="contact-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="hero__title">{title}</h1>
              </div>
              {!!contacts.length && (
                <>
                  <div className="col-lg-4 col-md-4 col-12">
                    {contacts.map(({ information, title: contactTitle }) => (
                      <div className="contact" key={slugify(contactTitle)}>
                        <h2 className="contact__title h4">{contactTitle}</h2>
                        <div
                          className="contact__text"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: information }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-none d-md-block col-lg-1 col-md-1 col-12">
                    <div className="hero__spacer" />
                  </div>
                  <div className="hero__contacts col-lg-7 col-md-7 col-12">
                    <ContactForm />
                  </div>
                </>
              )}
              {!contacts.length && (
                <div className="col-xl-10 offset-xl-1">
                  <h1 className="hero__title">{title}</h1>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="map">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <div className="map__map">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: googleMap.key,
                      language: 'fr',
                      region: 'CA',
                    }}
                    defaultCenter={googleMap.center}
                    defaultZoom={googleMap.zoom}
                    options={{ styles: googleMapStyles }}
                  >
                    <MapMarker
                      infowindow={googleMap.marker.infowindow}
                      isOpen={googleMap.marker.isOpen}
                      lat={googleMap.marker.lat}
                      lng={googleMap.marker.lng}
                      onclick={this.toggleInfowindow}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </section>

        <TextImageUnder
          surtitle={i18n.t('the_gq')}
          title={focus.title}
          text={focus.text}
          image={focus.image}
        />

        <TextThreeColumns columns={informations} />
      </Layout>
    );
  }
}

Contact.contextType = AppContext;

export default withTranslation()(Contact);
