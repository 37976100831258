// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import fr from 'date-fns/locale/fr';
import { format, parseISO } from 'date-fns';

// i18n
import i18n from '../../i18n';

// Components
import Button from '../Button';

// Styles
import './_featured-card.scss';

const FeaturedCard = ({ beginDate, endDate, image, link, title, overwriteClasse, beginTime }) => {
  beginDate = format(parseISO(beginDate), 'dd MMMM yyyy', {
    locale: fr,
  });

  endDate = format(parseISO(endDate), 'dd MMMM yyyy', {
    locale: fr,
  });

  const cardClasse = `featured-card col-md-6 card-list__item`;
  return (
    <div className={overwriteClasse ? overwriteClasse : cardClasse}>
      <a href={link}>
        <picture className="featured-card__image" data-content="Découvrir">
          <source media="(min-width: 768px)" srcSet={image.sizes.desktop} />
          <img src={image.sizes.mobile} alt={image.title} />
        </picture>
        <div className="featured-card__content">
          <h3 className="featured-card__title">{title}</h3>
          <p className="featured-card__date">
            {beginDate}
            {!!endDate && ` - ${endDate}`} à {beginTime}
          </p>
        </div>
      </a>
    </div>
  );
};

FeaturedCard.propTypes = {
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  beginTime: PropTypes.string,
};

export default FeaturedCard;
