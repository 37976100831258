// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Date
import fr from 'date-fns/locale/fr'; // register it with the name you want
import { format, parseISO } from 'date-fns';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Card from '../components/Card';

// Utils
import { getContests } from '../utils/wordpressApi';

class Contest extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      metatags: {},
      pathname,
      contests: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;
    const { pathname } = this.state;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getContests();

    if (this._isMounted && !!data) {
      const { metatags, contests } = data;
      let contestsList = [];

      if (Array.isArray(contests) && contests.length > 0) {
        contestsList = contests.map(
          ({ beginDate, endDate, id, slug, thumbnail, title }) => {
            return {
              beginDate: format(parseISO(beginDate), 'dd MMMM yyyy', {
                locale: fr,
              }),
              endDate: format(parseISO(endDate), 'dd MMMM yyyy', {
                locale: fr,
              }),
              id,
              link: `${pathname}/${slug}`,
              slug,
              thumbnail,
              title,
            };
          }
        );
      }

      this.setState({
        metatags,
        contests: contestsList,
      });
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { contests, metatags } = this.state;

    return (
      <Layout className="contest-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h1 className="hero__title">{i18n.t('contests').title}</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="contest-page__list">
          <div className="container">
            <div className="row">
              {!!contests &&
                !!contests.length &&
                contests.map(
                  ({ beginDate, endDate, link, slug, thumbnail, title }) => {
                    return (
                      <Card
                        key={slug}
                        title={title}
                        image={{
                          sizes: {
                            medium: thumbnail.sizes.landscapeWideMd,
                            small: thumbnail.sizes.landscapeWideSm,
                          },
                          title: thumbnail.title,
                        }}
                        caption={`${beginDate} - ${endDate}`}
                        slug={slug}
                        to={link}
                        hover={i18n.t('see_more')}
                      />
                    );
                  }
                )}
              {(!contests || !contests.length) && (
                <div className="col-lg-10 offset-lg-1">
                  {i18n.t('contests').no_results}
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Contest.contextType = AppContext;

export default withTranslation()(Contest);
