// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';

// Utils
import { renderHtml } from '../utils/renderHtml';

class Order extends Component {
  render() {
    return (
      <Layout className="order-page">
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <h1 className="hero__title">{i18n.t('online_order')}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="order-page__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <iframe src="/commande-en-ligne.html"></iframe>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default withTranslation()(Order);
