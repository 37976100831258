// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';
import { Redirect } from 'react-router-dom';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Card from '../components/Card';
import CtaButtons from '../components/CtaButtons';
import Carousel from '../components/Carousel';
import Button from '../components/Button';
import DropdownCat from '../components/DropdownCat';
import SectionTitle from '../components/SectionTitle';

// Utils
import getLocalizedPath from '../utils/getLocalizedPath';
import { getRestaurants } from '../utils/wordpressApi';
import { stripHtml } from '../utils/renderHtml';
import filters from '../utils/filters';
import { slugify, shuffle } from '../utils/tools';
import { renderHtml } from '../utils/renderHtml';

class Restaurant extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      categories: [],
      chefs: { title: '', seeMore: false },
      filtered: [],
      introduction: '',
      isMobile: false,
      metatags: {},
      pathname,
      restaurants: [],
      logo: '',
      cacher: true,
      rooms: {
        title: '',
        images: [],
      },
      seasonalOffers: {
        title: '',
        items: [],
        seeMore: false,
      },
    };

    this.filterList = this.filterList.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getRestaurants();

    if (this._isMounted && !!data) {
      const {
        metatags,
        restaurants,
        seasonalOffers,
        settings: {
          chefsTitle,
          description: introduction,
          roomsTitle,
          roomsImages,
          seasonalOffersTitle,
        },
      } = data;

      this.setState(prevState => ({
        ...prevState,
        chefs: {
          title: chefsTitle,
          seeMore: false,
        },
        introduction,
        metatags,
        restaurants: shuffle(restaurants),
        rooms: {
          title: roomsTitle,
          images: roomsImages,
        },
        seasonalOffers: {
          title: seasonalOffersTitle,
          items: seasonalOffers,
          seeMore: false,
        },
      }));

      /**
       * Get distinct restaurant categories
       */


      const categories = restaurants
        // .filter(
        //   (restaurant, index, self) =>
        //     index ===
        //     self.findIndex(t => t.category.slug === restaurant.category.slug)
        // )
        .map(restaurant => {
          return restaurant.category;
        });

      this.setState({
        categories,
        filtered: this.filterList(null, null),
      });

      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Filter list
   */
  filterList(type, slug) {
    const { restaurants } = this.state;
    let filtered = [];

    if (!type || !slug) {
      filtered = [...restaurants];
    } else {
      this.props.history.replace({ pathname: '/categorie/'+slug});
      filtered = filters('category', slug, restaurants);
    }

    this.setState({
      filtered,
    });

    return filtered;
  }

  handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  render() {
    const {
      categories,
      chefs,
      filtered,
      introduction,
      isMobile,
      metatags,
      pathname,
      restaurants,
      rooms,
      logo,
      cacher,
      seasonalOffers,
    } = this.state;

    const {
      options: {
        links: { menu: menuLink },
      },
    } = this.context;

    let seasonalOffersList = seasonalOffers.items;

    if (isMobile && !seasonalOffers.seeMore) {
      seasonalOffersList = seasonalOffers.items.slice(0, 3);
    }

    let chefsList = [];

    if (isMobile && !chefs.seeMore) {
      chefsList = restaurants
        .slice(0, 3)
        .map(({ chef: { image, name }, slug, title }) => {
          return { image, name, slug, title };
        });
    } else {
      chefsList = restaurants.map(({ chef: { image, name }, slug, title }) => {
        return { image, name, slug, title };
      });
    }

    return (
      <Layout className="restaurant-listing" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="hero__title col-lg-3 offset-lg-1">
                <h1>{i18n.t('drink_and_eat')}</h1>
              </div>
              <div className="hero__text col-lg-6 offset-lg-1">
                {renderHtml(introduction)}
              </div>
            </div>
          </div>
        </section>

        <section className="restaurants-list">
          <div className="container">
            <div className="card-filters row">
              <div className="card-filters__category col-10 offset-1 col-md-8 offset-md-2 col-lg-4 offset-lg-1 col-xl-3">
                <DropdownCat
                  id="categoryDropdown"
                  onClick={this.filterList}
                  options={categories}
                  placeholder={i18n.t('menu').categories}
                  type="category"
                />
              </div>
              {/*
              <div className="card-filters__cta col-10 offset-1 col-md-8 offset-md-2 col-lg-4 offset-lg-2 offset-xl-3">
                <Button className="btn-outline" href={menuLink}>
                  {i18n.t('menu').consult}
                </Button>
              </div>
              */}
            </div>
            <div className="card-list row">
              {filtered.map(({ title, caption, category, image, slug, logo, cacher }) => {
                let to = `${pathname}/${slug}`;

                // Custom rewrite for bar page
                if (slug === 'la-plage-menu') {
                  to = `${getLocalizedPath('bar')}`;
                }

                return (
                  <Card
                    key={slug}
                    title={title}
                    caption={caption}
                    image={{
                      sizes: {
                        medium: image.sizes.medium,
                        small: image.sizes.medium,
                      },
                      title: image.title,
                    }}
                    subtitle={category.name}
                    slug={slug}
                    to={to}
                    hover={i18n.t('see_more')}
                    logo={logo}
                    cacher={cacher}
                  />
                );
              })}
            </div>
          </div>
        </section>

        <CtaButtons buttons={['order']} />

        {/*
					<section className="rooms-list">
	          <div className="container">
	            <div className="rooms-list--item">PLAN</div>
	          </div>
	        </section>
				*/}

        {!!seasonalOffersList && !!seasonalOffersList.length && (
          <section className="seasonal-offers">
            <div className="seasonal-offers__wrapper">
              <SectionTitle
                centered
                surtitle={i18n.t('seasonal_offers')}
                title={seasonalOffers.title}
              />

              <div className="container">
                <div className="seasonal-offers__list row">
                  {seasonalOffersList.map(
                    ({ image, name, price, restaurant }) => {
                      if (!name) {
                        return null;
                      }
                      return (
                        <Card
                          hover={i18n.t('see_more')}
                          key={`${slugify(name)}${restaurant}_seasonal_offer`}
                          image={{
                            sizes: {
                              medium: image.sizes.landscapeWideMd,
                              small: image.sizes.landscapeWideSm,
                            },
                            title: image.title,
                          }}
                          to={{
                            pathname: `${pathname}/${restaurant}`,
                            state: { anchor: 'menu-anchor' },
                          }}
                          price={`- ${price}`}
                          slug={restaurant}
                          title={name}
                        />
                      );
                    }
                  )}
                </div>
                {isMobile && !seasonalOffers.seeMore && (
                  <Button
                    className="seasonal-offers__see-more btn-outline d-block mx-auto"
                    onClick={() => {
                      this.setState(prevState => ({
                        ...prevState,
                        seasonalOffers: {
                          ...prevState.seasonalOffers,
                          seeMore: true,
                        },
                      }));
                    }}
                  >
                    {i18n.t('see_all')}
                  </Button>
                )}
              </div>
            </div>
          </section>
        )}

        <SectionTitle
          surtitle={i18n.t('craftsmen').our_craftsmen}
          title={chefs.title}
          centered
          classes="chefs-title"
        />

        <section className="chefs-list">
          <div className="chefs-list__wrapper">
            <div className="container">
              <div className="row">
                {chefsList.map(({ image, name, slug, title }) => {
                  if (!name) {
                    return null;
                  }
                  return (
                    <Card
                      key={`${slug}_chef`}
                      theme="dark"
                      title={name}
                      image={{
                        sizes: {
                          medium: image.sizes.landscapeWideMd,
                          small: image.sizes.landscapeWideSm,
                        },
                        title: image.title,
                      }}
                      subtitle={`${i18n.t('chef').title} ${title}`}
                      slug={slug}
                      to={{
                        pathname: `${pathname}/${slug}`,
                        state: { anchor: 'chef-anchor' },
                      }}
                      hover={i18n.t('see_more')}
                    />
                  );
                })}
              </div>
              {isMobile && !chefs.seeMore && (
                <Button
                  className="chefs-list__see-more btn-outline btn-light d-block mx-auto"
                  onClick={() => {
                    this.setState(prevState => ({
                      ...prevState,
                      chefs: {
                        ...prevState.chefs,
                        seeMore: true,
                      },
                    }));
                  }}
                >
                  {i18n.t('see_all_chefs')}
                </Button>
              )}
            </div>
          </div>
        </section>

        <CtaButtons buttons={['rent']} />

        <section className="rooms-slider">
          <Carousel items={rooms.images} />
        </section>
      </Layout>
    );
  }
}

Restaurant.contextType = AppContext;

export default withTranslation()(Restaurant);
