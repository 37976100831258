// Vendors
import React from 'react';

// styles
import './_loader.scss';

const Loader = () => (
  <div className="loading-overlay">
    <div className="loading-overlay__loader" />
  </div>
);

export default Loader;
