// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../context';

// i18n
import i18n from '../../i18n';

// Components
import Button from '../Button';

// Utils
import getLocalizedPath from '../../utils/getLocalizedPath';

// Styles
import './_cta-buttons.scss';

const CtaButtons = ({ buttons, customLink, customLabel }) => {
  return (
    <AppContext.Consumer>
      {context => {
        const {
          options: {
            links: { book: bookLink, grocery: groceryLink, order: orderLink },
          },
        } = context;

        return (
          <section className="cta-buttons">
            <div className="container">
              <div className="row">
                <div className="col-10 col-md-12 col-xl-10 col-xxl-8 mx-auto">
                  <div className="cta-buttons__list row">
                    {buttons.includes('order') && (
                      <div className="col-md-6">
                        <Button className="btn-outline" href={orderLink}>
                          {i18n.t('order_online')}
                        </Button>
                      </div>
                    )}
                    {buttons.includes('book') && (
                      <div className="col-md-6">
                        <Button className="btn-outline" href={bookLink}>
                          {i18n.t('book_a_table')}
                        </Button>
                      </div>
                    )}
                    {buttons.includes('grocery') && (
                      <div className="col-md-6">
                        <Button className="btn-outline" href={groceryLink}>
                          {i18n.t('online_grocery')}
                        </Button>
                      </div>
                    )}
                    {buttons.includes('rent') && (
                      <div className="col-md-8">
                        <Button
                          className="btn-outline"
                          to={getLocalizedPath('catering')}
                        >
                          {i18n.t('rooms').rent}
                        </Button>
                      </div>
                    )}
                    {buttons.includes('custom') && (
                      <div className="col-md-8">
                        <Button className="btn-outline small" href={customLink}>
                          {customLabel}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }}
    </AppContext.Consumer>
  );
};

CtaButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.oneOf(['order', 'book', 'grocery', 'rent', 'custom'])
  ),
  customLink: PropTypes.string,
  customLabel: PropTypes.string,
};

CtaButtons.defaultProps = {
  buttons: ['order'],
  customLink: '',
  customLabel: '',
};

export default CtaButtons;
