// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Button from '../components/Button';
import Card from '../components/Card';
import CtaButtons from '../components/CtaButtons';
import Slider from 'react-slick';

// Utils
import { getRestaurant } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';
import { slugify } from '../utils/tools';
import scrollToElement from '../utils/scrollToElement';

class RestaurantSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      bookLink: null,
      contact: {
        address: '',
        hours: '',
      },
      menuPdf: '',
      chef: {
        description: '',
        image: {
          sizes: {
            large: '',
            xl: '',
          },
          title: '',
        },
        name: '',
      },
      description: '',
      email: '',
      menu: [],
      metatags: {},
      redirect: null,
      slug: props.match.params.slug,
      thumbnail: {
        sizes: {
          large: '',
          xl: '',
        },
        title: '',
      },
      restaurant_galleries: [],
      title: '',
      url_livraison: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Get anchor
    const {
      location: { state: locationState },
    } = this.props;

    let anchor = null;

    if (
      !!locationState &&
      Object.prototype.hasOwnProperty.call(locationState, 'anchor')
    ) {
      const { anchor: locationAnchor } = locationState;
      anchor = locationAnchor;
    }

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getRestaurant(slug);

    if (this._isMounted) {
      if (!!data && data.status === 'publish') {
        const {
          address,
          bookLink,
          chef,
          description,
          email,
          menu,
          metatags,
          hours,
          thumbnail,
          title,
          menuPdf,
          restaurant_galleries,
          plats,
          url_livraison,
          titre_texte_experience_chef,
          texte_experience_chef,
          titre_bouton_experience_chef,
          url_experience_chef,
          titre_texte_experience_gourmet,
          texte_experience_gourmet,
          titre_bouton_experience_gourmet,
          url_experience_gourmet,
        } = data;

        this.setState(prevState => ({
          ...prevState,
          bookLink,
          chef,
          contact: {
            address,
            hours,
          },
          description,
          email,
          menu,
          metatags,
          thumbnail,
          title,
          menuPdf,
          restaurant_galleries,
          plats,
          url_livraison,
          titre_texte_experience_chef,
          texte_experience_chef,
          titre_bouton_experience_chef,
          url_experience_chef,
          titre_texte_experience_gourmet,
          texte_experience_gourmet,
          titre_bouton_experience_gourmet,
          url_experience_gourmet,
        }));

        // Handle anchor
        if (anchor !== null) {
          setTimeout(() => {
            scrollToElement(anchor, false);

            // Unset loading
            unsetEvent('loading');
          }, 500);
        }
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    if (anchor === null) {
      // Unset loading
      unsetEvent('loading');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      bookLink,
      chef,
      contact,
      description,
      email,
      metatags,
      menu,
      redirect,
      thumbnail,
      title,
      menuPdf,
      restaurant_galleries,
      plats,
      url_livraison,
      titre_texte_experience_chef,
      texte_experience_chef,
      titre_bouton_experience_chef,
      url_experience_chef,
      titre_texte_experience_gourmet,
      texte_experience_gourmet,
      titre_bouton_experience_gourmet,
      url_experience_gourmet,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    const {
      options: {
        links: { order: orderLink },
      },
    } = this.context;

    const items = restaurant_galleries || []
    const platsitems = plats || []

    return (
      <Layout className="restaurant-single" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="hero__text col-lg-4 offset-lg-1">
                <span className="hero__subtitle">
                  {i18n.t('restaurant').title}
                </span>
                <h1 className="hero__title small-space">
                  {title}
                </h1>
                {/* {!!menuPdf && (
                  <Button outline={false} href={menuPdf} className="space--bottom" target="_blank">
                    Télécharger le menu
                  </Button>
                )} */}
                {!!description && (
                  <div className="hero__introduction">
                    {renderHtml(description)}
                  </div>
                )}
                {!!menuPdf && (
                  <Button outline={false} href={menuPdf} className="" target="_blank">
                    Télécharger le menu
                  </Button>
                )}
                {!!plats && (
                  <div className="specialites">
                    <h3 className="flexTitle">
                      <span>Spécialités</span>
                    </h3>
                    {platsitems.map(item => {
                      return [<div><p className="title"><b>{item.nom}</b></p><div className="desc"><img src={item.image} /><p>{item.description}</p></div></div>]
                    })}
                  </div>
                )}
                <section className="contact opening-hours">
                  <h3 className="contact__subtitle">
                    {i18n.t('restaurant').hours}
                  </h3>
                  {renderHtml(contact.hours)}
                </section>
              </div>
              <div className="hero__thumbnail">
                <div className="row btns-box">
                  {!!url_livraison && (
                    <div className="col-lg-4 cold-md-4 col-12">
                      <a href={url_livraison} className="btn">Emporter et livraison</a>
                    </div>
                  )}
                  {!!url_experience_chef && (
                    <div className="col-lg-4 cold-md-4 col-12">
                      <a href={url_experience_chef} className="btn">{!!(titre_bouton_experience_chef)?titre_bouton_experience_chef:"Expérience chef"}</a>
                    </div>
                  )}
                  {!!url_experience_gourmet && (
                    <div className="col-lg-4 cold-md-4 col-12">
                      <a href={url_experience_gourmet} className="btn">{!!(titre_bouton_experience_gourmet)?titre_bouton_experience_gourmet:"Expérience gourmet"}</a>
                    </div>
                  )}
                </div>
                {!!thumbnail.sizes.squareSm && (
                  <div className="slider-cont">
                    <Slider
                      dots
                      arrows={true}
                      infinite
                      speed={1000}
                      autoplay
                      slidesToShow={1}
                      slidesToScroll={1}
                      pauseOnHover
                      swipeToSlide
                      swipe
                      touchThreshold={100}
                      responsive={[
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          },
                        },
                      ]}
                    >
                      {items.map(item => {
                        return <img src={item.url} />
                      })}
                    </Slider>
                  </div>
                )}
                <div className="row text-experience">
                  {!!texte_experience_chef && (
                    <div className="col-lg-6 col-md-6 col-12">
                      <p><b>{!!(titre_texte_experience_chef)?titre_texte_experience_chef:"L'expérience Chef"}</b> {texte_experience_chef}</p>
                    </div>
                  )}
                  {!!texte_experience_gourmet && (
                    <div className="col-lg-6 col-md-6 col-12">
                      <p><b>{!!(titre_texte_experience_gourmet)?titre_texte_experience_gourmet:"L'expérience Gourmet"}</b> {texte_experience_gourmet}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="menu-anchor" />
        </section>

        {!!menu && !!menu.length && (
          <section className="menu">
            <div className="container">
              <div className="menu__header row">
                <div className="menu__title col-lg-4 order-lg-1 order-3">
                  <h2 className="title-underline">{i18n.t('menu').our_menu}</h2>
                </div>
                <div className="menu__cta col-lg-4 order-lg-2 offset-xl-1 order-1">
                  <Button outline={false} href={orderLink} target="_blank">
                    {i18n.t('order_online')}
                  </Button>
                </div>
                {/*
                <div className="menu__cta col-lg-4 col-xl-3 order-lg-3 order-2">
                  <Button outline={false} href={bookLink} target="_blank">
                    {i18n.t('book_a_table')}
                  </Button>
                </div>
                */}
              </div>

              <div className="menu__list row">
                {Object.entries(menu).map(([key, obj]) => {
                  return (
                    <article key={key} className="menu__sub-list col-12">
                      <h3>{i18n.t('menu')[key]}</h3>

                      <div className="card-list row">
                        {obj.items.map(
                          ({ name, description: caption, image, price }) => {
                            return (
                              <Card
                                key={slugify(name)}
                                title={name}
                                caption={caption}
                                image={{
                                  sizes: {
                                    medium: image.sizes.landscapeWideMd,
                                    small: image.sizes.landscapeWideSm,
                                  },
                                  title: image.title,
                                }}
                                hover={i18n.t('see_more')}
                                price={`- ${price}`}
                              />
                            );
                          }
                        )}
                      </div>
                    </article>
                  );
                })}
              </div>
            </div>
          </section>
        )}

        {/* {!!bookLink && (
          <CtaButtons
            buttons={['custom']}
            customLink={bookLink}
            customLabel={i18n.t('book_a_table')}
          />
        )} */}

        {!!chef.name && (
          <section className="chef spacer--top--large">
            <div id="chef-anchor" />
            <div className="container">
              <div className="row">
                <div className="chef__image col-lg-5 offset-lg-1">
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet={chef.image.sizes.portraitLg}
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={chef.image.sizes.landscapeWideMd}
                    />
                    <img
                      src={chef.image.sizes.landscapeWideSm}
                      alt={chef.image.title}
                    />
                  </picture>
                </div>
                <div className="chef__description col-lg-4 offset-lg-1">
                  <div className="chef__text">
                    <h2 className="chef__title">
                      <span className="chef__surtitle">
                        {i18n.t('chef').meet}
                      </span>
                      {chef.name}
                    </h2>
                    {renderHtml(chef.description)}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <h2 className="contact__title title-underline">
                  {i18n.t('contact')}
                </h2>
              </div>
              <div className="contact__location col-lg-3 offset-lg-1">
                <h3 className="contact__subtitle">
                  {renderHtml(contact.address)}
                </h3>
                {!!email && (
                  <a className="contact__email" href={`mailto:${email}`}>
                    {email}
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

RestaurantSingle.contextType = AppContext;

export default withTranslation()(RestaurantSingle);
