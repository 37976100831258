// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './_hero.scss';

const Hero = ({ centered, title }) => {
  let classes = '';
  if (!centered) classes = 'col-xl-10 offset-xl-1';
  else classes = 'col-12 text-center';

  return (
    <section className="simple-hero">
      <div className="container">
        <div className="row">
          <div className={classes}>
            <h1 className="simple-hero__title">{title}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  centered: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Hero.defaultProps = {
  centered: false,
};

export default Hero;
