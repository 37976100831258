// vendors
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// styles
import './_accordion.scss';

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const isChecked = e.target.checked;

    this.setState({ checked: isChecked });
  }

  render() {
    const { children, title, id } = this.props;
    const { checked } = this.state;

    let optionClasses = 'accordion__option';
    if (checked) optionClasses += ' accordion__option--is-checked';

    return (
      <article className="accordion">
        <div className={optionClasses}>
          <label className="accordion__title" htmlFor={id}>
            <input
              type="checkbox"
              id={id}
              className="accordion__toggle"
              onChange={this.handleChange}
            />
            <h3>{title}</h3>
          </label>
          <div className="accordion__content">{children}</div>
        </div>
      </article>
    );
  }
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Accordion;
