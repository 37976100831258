// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Styles
import './_carousel.scss';

const Carousel = ({ items }) => {
  return (
    <section className="carousel">
      <Slider
        dots
        arrows={false}
        infinite
        speed={1000}
        autoplay
        slidesToShow={3}
        slidesToScroll={1}
        swipeToSlide
        pauseOnHover
        swipe
        touchThreshold={100}
      >
        {items.map((item, index) => (
          <article
            key={`s_${item.id}`}
            id={`background_${index}`}
            className="carousel__item"
          >
            <picture>
              <source media="(min-width: 768px)" srcSet={item.sizes.xl} />
              <img src={item.sizes.large} alt={item.title} />
            </picture>
          </article>
        ))}
      </Slider>
    </section>
  );
};

Carousel.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Carousel.defaultProps = {
  items: [],
};

export default Carousel;
