// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Button from '../components/Button';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import Dropdown from '../components/Dropdown';
import Layout from '../components/Layout';
// import SectionTitle from '../components/SectionTitle';
import TextImage from '../components/TextImage';

// Utils
import { getCafe } from '../utils/wordpressApi';
import filters from '../utils/filters';

class Cafe extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      titre: "",
      texte: "",
      texte_bouton_1: "",
      url_bouton_1: "",
      image_texte: "",
      gallerie: [],
      filtered: [],
      metatags: {},
      pathname,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getCafe();

    if (this._isMounted && !!data) {
      const {
        metatags,
        titre,
        texte,
        image_texte,
        gallerie,
        texte_bouton_1,
        url_bouton_1,
      } = data;

      this.setState(prevState => ({
        ...prevState,
        metatags,
        titre,
        texte,
        image_texte,
        gallerie,
        texte_bouton_1,
        url_bouton_1,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      metatags,
      titre,
      texte,
      image_texte,
      gallerie,
      texte_bouton_1,
      url_bouton_1,
    } = this.state;

    return (
      <Layout className="artisan-page" metatags={metatags}>
        {!!texte?.length && (
          <TextImage
            image={{
              sizes: {
                desktop: image_texte.url,
                mobile: image_texte.url,
              },
              title: image_texte.title,
            }}
            reversed
            text={texte}
            title={titre}
            button={{
              text: texte_bouton_1,
              href: url_bouton_1,
              target: "blank",
            }}
          />
        )}
        {!!gallerie?.length && (
          <section className="shops__slider">
            <Carousel
              items={gallerie.map(image => {
                return {
                  sizes: {
                    large: image.url,
                    xl: image.url,
                  },
                  title: image.title,
                };
              })}
            />
          </section>
        )}
      </Layout>
    );
  }
}

Cafe.contextType = AppContext;

export default withTranslation()(Cafe);
