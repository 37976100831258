import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

// Utils
import { getOptionPage } from './utils/wordpressApi';

// AppContext
export const AppContext = createContext();

class AppProvider extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options: {
        social: {
          facebook: '',
          instagram: '',
          youtube: '',
          pinterest: '',
        },
        links: {
          book: '',
          grocery: '',
          itinerary: '',
          order: '',
          menu: '',
        },
        schema: {
          name: '',
          telephone: '',
          description: '',
          streetAddress: '',
          postalCode: '',
          addressLocality: '',
          addressRegion: '',
          addressCountry: '',
          openingHours: [],
        },
      },
      setEvent: event => {
        this.setState(prevState => ({
          ...prevState,
          [event]: true,
        }));
      },
      unsetEvent: event => {
        this.setState(prevState => ({
          ...prevState,
          [event]: false,
        }));
      },
    };
  }

  getOptions = async () => {
    if (this._isMounted) {
      const data = await getOptionPage('options');

      if (data) {
        const {
          acf: { facebook, instagram, youtube, pinterest, links, schema },
        } = data;

        this.setState({
          options: {
            social: {
              facebook,
              instagram,
              youtube,
              pinterest,
            },
            links,
            schema,
          },
        });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.getOptions();
  }

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider value={{ ...this.state }}>
        {children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
