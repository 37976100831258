// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import EventSpaceForm from '../components/EventSpaceForm';
import Layout from '../components/Layout';
import TextImage from '../components/TextImage';

// Utils
import { getEventSpace } from '../utils/wordpressApi';

class EventSpace extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      thumbnail: { sizes: { large: '', xl: '' }, title: '' },
      metatags: {},
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getEventSpace();

    if (this._isMounted && !!data) {
      const { content, metatags, thumbnail, title } = data;

      this.setState(prevState => ({
        ...prevState,
        content,
        metatags,
        thumbnail,
        title,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { content, metatags, thumbnail, title } = this.state;

    return (
      <Layout className="event-space-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <h1 className="hero__title">{i18n.t('event_space')}</h1>
              </div>
            </div>
          </div>
        </section>

        <TextImage
          image={{
            sizes: {
              desktop: thumbnail.sizes.portraitLg,
              mobile: thumbnail.sizes.landscapeWideSm,
            },
            title: thumbnail.title,
          }}
          reversed
          text={content}
          title={title}
        />

        <EventSpaceForm title="Contactez-nous" />
      </Layout>
    );
  }
}

EventSpace.contextType = AppContext;

export default withTranslation()(EventSpace);
