// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './_text-full-width.scss';

const TextImageUnder = ({ logo, text, title }) => {
  return (
    <section className="text-full-width">
      <div className="text-full-width__grid" />
      <div className="text-full-width__container">
        <div className="mx-auto col-lg-10 col-xl-9 col-xxl-8">
          <div className="text-full-width__container--title">
            <p className="subtitle">{title}</p>
          </div>
          <div className="text-full-width__container--text">
            <h2>{text}</h2>
            {!!logo && (
              <svg viewBox="0 0 155 100">
                <path d="m9.17 28.44c0-2.28 0.02-4.55 0-6.83-0.01-1.66-0.54-2.17-2.22-2.18-1.22-0.01-2.44-0.01-3.66 0-1.82 0.01-2.3 0.5-2.31 2.35v20.87 21.12c0 1.51 0.55 2.07 2.03 2.08 5.23 0.01 10.46 0.01 15.69 0 1.43 0 1.95-0.55 1.97-2.01 0.02-1.35 0.01-2.7 0-4.05-0.01-1.63-0.46-2.06-2.13-2.07-2.7 0-5.4-0.06-8.1 0.03-1.1 0.04-1.3-0.33-1.3-1.35 0.04-9.32 0.03-18.64 0.03-27.96z" />
                <path d="m0.97 75.87c0 2.16 0.43 2.59 2.56 2.59h30.61c3.3 0 6.58 0.01 9.87 0 1.54 0 2.11-0.55 2.14-2.01 0.02-1.1 0-2.2 0-3.29 0.01-2.23-0.42-2.67-2.63-2.67h-39.98-0.63c-1.32 0.07-1.92 0.67-1.94 1.96-0.01 1.14 0 2.28 0 3.42z" />
                <path d="m0.98 12.02c-0.01 2.4 0.31 2.72 2.66 2.72h20.01 20.39c1.61 0 2.1-0.51 2.13-2.12 0.01-1.01 0-2.02 0-3.04 0-2.41-0.31-2.73-2.67-2.73h-38.88c-0.59 0-1.18-0.02-1.77 0-0.92-0.11-1.76 0.53-1.87 1.45-0.02 0.14-0.02 0.29 0 0.43-0.02 1.09 0 2.19 0 3.29z" />
                <path d="m102.02 61.33c0.09-12.74 0.06-25.48 0.01-38.22-0.06-8.9-5.56-16.87-13.87-20.07-3.85-1.26-7.92-1.76-11.96-1.47-11.76 0.1-21.29 9.56-21.48 21.31-0.09 12.82-0.16 25.64 0.03 38.47-0.16 7.62 3.86 14.72 10.48 18.5 10.01 6 20.08 11.9 30.13 17.83 0.4 0.24 0.82 0.43 1.26 0.58 1.42 0.63 3.08 0.01 3.74-1.39 0.92-1.75 2.03-3.4 3.06-5.09 1.08-1.45 0.79-3.5-0.65-4.58-0.19-0.14-0.39-0.26-0.6-0.36-3.21-1.93-6.44-3.85-9.65-5.79-0.38-0.23-0.86-0.37-1.15-0.85 0.35-0.24 0.7-0.46 1.02-0.7 6.03-4.09 9.63-10.89 9.63-18.17zm-14.96-0.99c0.1 5.05-3.91 9.22-8.96 9.32-0.27 0-0.55 0-0.82-0.02-4.93-0.46-8.67-4.65-8.57-9.6-0.08-6.92-0.09-13.83-0.13-20.75-0.03-5.4-0.09-10.81-0.07-16.2 0.08-4.74 3.85-8.59 8.59-8.77 4.42-0.5 8.53 2.36 9.59 6.68 0.17 0.78 0.24 1.58 0.21 2.38v18.1h0.15c0.02 6.29 0.05 12.57 0.01 18.86z" />
                <path d="m154 44.93c0.13-1.57-1.03-2.94-2.6-3.07-0.17-0.01-0.33-0.01-0.5 0-6.72-0.01-13.43-0.01-20.13 0-1.63-0.11-3.05 1.12-3.16 2.76-0.01 0.14-0.01 0.27 0 0.41-0.02 2.53 0 5.06 0 7.6 0 2.1 0.89 3.01 2.99 3.02 2.45 0.01 4.9 0.03 7.34-0.01 0.77-0.01 1.04 0.19 1 0.99-0.06 1.47 0.02 2.95-0.03 4.43 0.08 5.04-3.93 9.19-8.97 9.27h-0.1c-5.2-0.08-9.36-4.34-9.3-9.54-0.02-12.41-0.02-24.81 0-37.22-0.07-3.84 2.4-7.28 6.06-8.44 2.03-0.6 4.18-0.7 6.26-0.3 4.01 0.57 5.71 2.28 6.52 6.27 0.39 1.9 1.05 2.45 3.02 2.46 2.87 0.01 5.74 0 8.61 0 2.4 0 3.06-0.75 2.84-3.17-0.79-8.72-5.6-14.27-13.62-17.3-3.97-1.2-8.12-1.63-12.25-1.28-11.78 0.04-21.32 9.56-21.39 21.33-0.08 12.95-0.07 25.91 0.01 38.86-0.01 6.83 3.16 13.28 8.57 17.45 6.4 5.24 13.86 5.34 21.51 4.23 2.66-0.51 5.18-1.58 7.38-3.15v1.4c0.01 1.8 0.6 2.38 2.45 2.39 1.69 0 3.38 0.01 5.07 0 1.17 0.15 2.25-0.67 2.41-1.85 0.03-0.19 0.02-0.39 0-0.58 0.03-12.32 0.03-24.64 0.01-36.96z" />
                <path d="m32.24 27.66c3.93 0.06 7.85 0.03 11.77 0.02 1.48 0 2.08-0.59 2.1-2.06 0.02-1.14 0-2.28 0-3.42 0-2.13-0.48-2.61-2.58-2.61h-17.85c-2.13 0-2.61 0.49-2.62 2.6v20.38 20.76c0 1.79 0.56 2.36 2.33 2.36 6.21 0.01 12.41 0.01 18.61 0 1.5 0 2.07-0.59 2.1-2.06 0.02-1.18 0-2.36 0-3.54 0-1.98-0.49-2.47-2.46-2.47-3.85 0-7.68-0.02-11.52 0.01-0.76 0-1.1-0.11-1.1-1.01 0.06-3.33 0.05-6.67 0-10-0.01-0.81 0.26-0.98 1.02-0.97 2.91 0.04 5.83 0.03 8.74 0.01 1.47 0 1.95-0.51 1.96-1.99 0.01-1.26 0-2.53 0-3.8-0.01-1.65-0.47-2.12-2.09-2.13-2.79 0-5.57-0.04-8.36 0.02-0.93 0.02-1.32-0.14-1.28-1.21 0.09-2.57 0.08-5.15 0-7.72 0.01-0.95 0.29-1.18 1.23-1.17z" />
              </svg>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TextImageUnder.propTypes = {
  logo: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};

TextImageUnder.defaultProps = {
  logo: true,
  title: '',
};

export default TextImageUnder;
