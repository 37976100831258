/**
 * Filter items
 * [Only for single filter]
 */
export default function filters(type, search, items) {
  if (type === 'category') {
    return items.filter(item => item.category.slug === search);
  }

  if (type === 'title') {
    if (search.length >= 3) {
      return items.filter(item => item.title.toLowerCase().search(search) > -1);
    }
  }

  return items;
}
