// Vendors
import React from 'react';
import { withTranslation } from 'react-i18next';

import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import TextCenter from '../components/TextCenter';

// Utils
import getLocalizedPath from '../utils/getLocalizedPath';

const Error404 = () => {
  const metatags = {
    title: i18n.t('error404_title'),
    description: i18n.t('error404_text'),
    robots: 'noindex,nofollow',
  };

  return (
    <Layout className="error-404" metatags={metatags}>
      <TextCenter
        button={{
          text: i18n.t('error404_button'),
          to: getLocalizedPath('home'),
        }}
        surtitle={i18n.t('error404_title')}
        title={i18n.t('error404_text')}
      />
    </Layout>
  );
};

export default withTranslation()(Error404);
