// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// i18n
import i18n from '../../i18n';

// Components
import Input from '../Input';

// Assets
import SearchSVG from '../../assets/svg/search';

// Styles
import './_search-input.scss';

const SearchInput = ({ error, handleChange, handleClick, id, name, value }) => {
  return (
    <div className="search-input">
      <Input
        error={error}
        id={id}
        label={i18n.t('search')}
        name={name}
        handleChange={handleChange}
        type="text"
        value={value}
      />
      <button
        className="search-input__button"
        onClick={handleClick}
        type="button"
      >
        <span className="d-none">{i18n.t('search')}</span>
        <SearchSVG />
      </button>
    </div>
  );
};

SearchInput.propTypes = {
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  error: false,
  value: '',
};

export default SearchInput;
