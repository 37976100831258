import fetch from 'node-fetch';

/*
 * Get Event
 */
export async function getEvent(eventId = null) {
  if (eventId === null) return false;

  try {
    const response = await fetch(
      `https://www.eventbriteapi.com/v3/events/${eventId}/`,
      {
        headers: {
          Authorization: `Bearer ${process.env.EVENTBRITE_TOKEN}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}

/*
 * Get Places Left
 */
export async function getPlacesLeft(eventId = null) {
  if (eventId === null) return false;

  try {
    const response = await fetch(
      `https://www.eventbriteapi.com/v3/events/${eventId}/capacity_tier/`,
      {
        headers: {
          Authorization: `Bearer ${process.env.EVENTBRITE_TOKEN}`,
        },
      }
    );
    const data = await response.json();

    const {
      quantity_pending: pending,
      quantity_sold: sold,
      quantity_total: total,
    } = data;

    return total - sold - pending;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return false;
  }
}
