const routes = [
  {
    component: 'Home',
    exact: true,
    key: 'home',
    paths: {
      fr: '/',
    },
  },
  {
    component: 'RestaurantSingle',
    key: 'restaurant-single',
    paths: {
      fr: '/restaurants/:slug',
    },
  },
  {
    component: 'Restaurant',
    key: 'restaurant',
    paths: {
      fr: '/restaurants',
    },
  },
  {
    component: 'RestaurantCat',
    key: 'restaurant-cat',
    paths: {
      fr: '/categorie/:slug',
    },
  },
  // {
  //   component: 'Menu',
  //   key: 'menu',
  //   paths: {
  //     fr: '/menu',
  //   },
  // },
  {
    component: 'ArtisanSingle',
    key: 'artisan-single',
    paths: {
      fr: '/epicerie/:slug',
    },
  },
  {
    component: 'Artisan',
    key: 'artisan',
    paths: {
      fr: '/epicerie',
    },
  },
  {
    component: 'Cafe',
    key: 'cafe',
    paths: {
      fr: '/cafe-italien',
    },
  },
  {
    component: 'Bar',
    key: 'bar',
    paths: {
      fr: '/bar-a-cocktails',
    },
  },
  {
    component: 'BlogSingle',
    key: 'blog-single',
    paths: {
      fr: '/blogue/:slug',
    },
  },
  {
    component: 'Blog',
    key: 'blog',
    paths: {
      fr: '/blogue',
    },
  },
  {
    component: 'EventSingle',
    key: 'event-single',
    paths: {
      fr: '/evenements/:slug',
    },
  },
  {
    component: 'Event',
    key: 'event',
    paths: {
      fr: '/evenements',
    },
  },
  {
    component: 'PromotionSingle',
    key: 'promotion-single',
    paths: {
      fr: '/promotions/:slug',
    },
  },
  {
    component: 'Promotion',
    key: 'promotion',
    paths: {
      fr: '/promotions',
    },
  },
  {
    component: 'ContestSingle',
    key: 'contest-single',
    paths: {
      fr: '/concours/:slug',
    },
  },
  {
    component: 'Contest',
    key: 'contest',
    paths: {
      fr: '/concours',
    },
  },
  {
    component: 'JobSingle',
    key: 'job-single',
    paths: {
      fr: '/emplois/:slug',
    },
  },
  {
    component: 'Job',
    key: 'job',
    paths: {
      fr: '/emplois',
    },
  },
  {
    component: 'About',
    key: 'about',
    paths: {
      fr: '/a-propos',
    },
  },
  {
    component: 'Catering',
    key: 'catering',
    paths: {
      fr: '/groupes',
    },
  },
  // {
  //   component: 'EventSpace',
  //   key: 'event-space',
  //   paths: {
  //     fr: '/espace-evenementiel',
  //   },
  // },
  {
    component: 'TermsAndConditions',
    key: 'terms-and-conditions',
    paths: {
      fr: '/terms-and-conditions',
    },
  },
  {
    component: 'TermsAndConditionsCard',
    key: 'card-conditions',
    paths: {
      fr: '/terms-and-conditions-carte',
    },
  },
  {
    component: 'PrivacyPolicy',
    key: 'privacy-policy',
    paths: {
      fr: '/politique-de-confidentialite',
    },
  },
  {
    component: 'ClientService',
    key: 'client-service',
    paths: {
      fr: '/service-client',
    },
  },
  {
    component: 'Contact',
    key: 'contact',
    paths: {
      fr: '/contact',
    },
  },
  {
    component: 'Faq',
    key: 'faq',
    paths: {
      fr: '/faq',
    },
  },
  {
    component: 'Vip',
    key: 'vip',
    paths: {
      fr: '/programme-vip',
    },
  },
  {
    component: 'Order',
    key: 'order',
    paths: {
      fr: '/commande-en-ligne',
    },
  },
  {
    component: 'NosMenus',
    key: 'nosMenus',
    paths: {
      fr: '/nos-menus',
    },
  },
  // {
  //   component: 'Consent',
  //   key: 'consent',
  //   paths: {
  //     fr: '/consent',
  //   },
  // },
  // {
  //   component: 'EmailValidation',
  //   key: 'email-validation',
  //   paths: {
  //     fr: '/email-validation',
  //   },
  // },
  // {
  //   component: 'RegistrationForm',
  //   key: 'registration-form',
  //   paths: {
  //     fr: '/registration-form',
  //   },
  // },
  // {
  //   component: 'PairingForm',
  //   key: 'pairing-form',
  //   paths: {
  //     fr: '/pairing-form',
  //   },
  // },
  // {
  //   component: 'ProfileToCompleteForm',
  //   key: 'profile-to-complete',
  //   paths: {
  //     fr: '/profile-to-complete',
  //   },
  // },
  // {
  //   component: 'Dashboard',
  //   key: 'dashboard',
  //   paths: {
  //     fr: '/mon-compte',
  //   },
  // },
];

export default routes;
