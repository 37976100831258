import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  className,
  disabled,
  error,
  handleChange,
  id,
  label,
  max,
  maxLength,
  min,
  name,
  type,
  value,
  hidden,
}) => {
  let inputClasses = 'form-input';
  if (error) inputClasses += ' is-invalid';
  if (disabled) inputClasses += ' is-disabled';
  if (hidden) inputClasses += ' is-hidden';
  if (className) inputClasses += ` ${className}`;

  return (
    <div className="form-group">
      <input
        className={inputClasses}
        id={id}
        name={name}
        type={type}
        value={value}
        max={max}
        maxLength={maxLength}
        min={min}
        disabled={disabled}
        onChange={handleChange}
        autoComplete="off"
      />
      <label htmlFor={id} className="form-label">
        {label}
      </label>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  label: '',
  max: null,
  maxLength: null,
  min: null,
  type: 'text',
  value: '',
  hidden: false
};

export default Input;
