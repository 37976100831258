// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Hero from '../components/Hero';
import JobForm from '../components/JobForm';
import Layout from '../components/Layout';

// Utils
import { getJob } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';

class JobSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      metatags: {},
      redirect: null,
      slug: props.match.params.slug,
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getJob(slug);

    if (this._isMounted) {
      if (data) {
        const { content, metatags, title } = data;

        this.setState(prevState => ({
          ...prevState,
          content,
          metatags,
          title,
        }));
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { content, metatags, redirect, title } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    return (
      <Layout className="job-page" metatags={metatags}>
        {!!title && (
          <>
            <Hero title={title} />

            <section className="job-page__content">
              <div className="container">
                <div className="row">
                  <div className="col-md-11 col-lg-9 col-xl-8 offset-xl-1 col-xxl-7">
                    {renderHtml(content)}
                  </div>
                </div>
              </div>
            </section>

            <JobForm title={i18n.t('join_our_team')} job={title} />
          </>
        )}
      </Layout>
    );
  }
}

JobSingle.contextType = AppContext;

export default withTranslation()(JobSingle);
