// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import TextImage from '../components/TextImage';

// Utils
import { getVip } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';

class Vip extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      introduction: {
        content: '',
        title: '',
        thumbnail: { sizes: { large: '', xl: '' }, title: '' },
      },
      showForm: false,
      metatags: {},
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getVip();

    if (this._isMounted && !!data) {
      const { content, introduction, metatags, showForm, thumbnail } = data;

      this.setState(prevState => ({
        ...prevState,
        content,
        introduction,
        metatags,
        showForm,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { content, introduction, metatags, showForm, thumbnail } = this.state;

    return (
      <Layout className="vip-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <h1 className="hero__title">{i18n.t('vip_program')}</h1>
              </div>
            </div>
          </div>
        </section>

        <TextImage
          image={{
            sizes: {
              desktop: introduction.thumbnail.sizes.portraitLg,
              mobile: introduction.thumbnail.sizes.landscapeWideSm,
            },
            title: introduction.thumbnail.title
              ? introduction.thumbnail.title
              : introduction.title,
          }}
          reversed
          text={introduction.content}
          title={introduction.title}
        />

        <section className="vip-page__content">
          <div className="container">
            <div className="row">
              {!!content && (
                <div className="vip-page__content--text col-lg-10 offset-lg-1">
                  {renderHtml(content)}
                </div>
              )}
              {!!showForm && (
                <div className="vip-page__content--form col-lg-10 offset-lg-1">
                  <iframe src="/vip-form.html"></iframe>
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Vip.contextType = AppContext;

export default withTranslation()(Vip);
