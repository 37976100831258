import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ error, handleChange, id, name, label, value }) => {
  return (
    <div className="form-group form-group__textarea">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        className={`form-textarea${error ? ' border-danger' : ''}`}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

Textarea.propTypes = {
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
};

Textarea.defaultProps = {
  error: false,
  label: '',
  value: '',
};

export default Textarea;
