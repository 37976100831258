import parse from 'html-react-parser';

/*
 * Render HTML
 */
export function renderHtml(string) {
  return parse(string);
}

/*
 * Strip HTML tags
 */
export function stripHtml(string) {
  const regex = /(<([^>]+)>)/gi;
  return string.replace(regex, '');
}

/*
 * Truncate string
 */
export function truncateString(str, length) {
  if (!length) {
    length = 50;
  }

  return str.length > length ? `${str.substring(0, length - 3)}...` : str;
}
