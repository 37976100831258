import React from 'react';

const InstagramSVG = () => (
  <svg viewBox="0 0 20 20">
    <path d="m15.33 3.41c-0.28 0-0.55 0.1-0.76 0.29-0.46 0.42-0.5 1.14-0.08 1.61s1.14 0.5 1.6 0.08 0.5-1.14 0.08-1.61c-0.22-0.23-0.52-0.36-0.84-0.37z" />
    <path d="m9.95 4.86c-1.37-0.03-2.69 0.52-3.64 1.5-0.97 0.96-1.51 2.27-1.5 3.64-0.02 1.37 0.52 2.69 1.5 3.64 0.96 0.97 2.28 1.51 3.64 1.5 1.37 0.03 2.69-0.52 3.64-1.5 0.97-0.96 1.51-2.28 1.5-3.64 0.02-1.37-0.52-2.69-1.5-3.64-0.94-1-2.26-1.55-3.64-1.5zm2.38 7.48c-1.32 1.31-3.45 1.31-4.77 0-0.65-0.6-1.01-1.45-0.98-2.34-0.01-0.9 0.34-1.76 0.99-2.39 1.32-1.31 3.45-1.31 4.77 0 0.63 0.63 0.99 1.49 0.98 2.38 0 0.89-0.35 1.73-0.99 2.35z" />
    <path d="m18.32 1.68c-1.13-1.08-2.64-1.66-4.21-1.64-0.75-0.04-2.1-0.04-4.11-0.04-1.96 0-3.36 0-4.11 0.05-1.56-0.01-3.06 0.58-4.21 1.63-1.07 1.13-1.66 2.64-1.64 4.2-0.04 0.76-0.04 2.16-0.04 4.12s0 3.36 0.05 4.11c0 1.56 0.58 3.06 1.64 4.21 1.13 1.08 2.64 1.66 4.21 1.64 0.74 0.04 2.09 0.04 4.1 0.04 1.96 0 3.36 0 4.11-0.05 1.56 0 3.06-0.59 4.21-1.63 1.07-1.13 1.66-2.64 1.64-4.21 0.04-0.75 0.04-2.11 0.04-4.11 0-2.01-0.05-3.36-0.05-4.11 0-1.56-0.59-3.06-1.63-4.21zm-0.1 10.66c0 0.33-0.05 0.75-0.05 1.26-0.05 0.56-0.09 0.98-0.14 1.36-0.04 0.32-0.12 0.63-0.23 0.93-0.16 0.44-0.42 0.84-0.75 1.17-0.35 0.31-0.74 0.56-1.17 0.75-0.3 0.12-0.61 0.2-0.93 0.23-0.44 0.1-0.9 0.15-1.36 0.14-0.51 0.05-0.94 0.05-1.26 0.05h-4.67c-0.33 0-0.7 0-1.26-0.05s-0.98-0.09-1.36-0.14c-0.31-0.04-0.63-0.12-0.93-0.24-0.44-0.16-0.83-0.42-1.17-0.74-0.31-0.35-0.56-0.74-0.75-1.17-0.09-0.31-0.17-0.62-0.23-0.93-0.1-0.44-0.15-0.9-0.14-1.36-0.05-0.51-0.05-0.93-0.05-1.26v-4.68c0-0.33 0.05-0.75 0.05-1.26 0.05-0.56 0.09-0.98 0.14-1.36 0.04-0.31 0.12-0.63 0.24-0.93 0.16-0.44 0.42-0.84 0.75-1.17 0.34-0.3 0.74-0.56 1.16-0.74 0.3-0.12 0.62-0.2 0.94-0.24 0.44-0.1 0.9-0.15 1.36-0.14 0.51-0.05 0.94-0.05 1.26-0.05h3.31 1.36c0.33 0 0.75 0.05 1.26 0.05 0.56 0.05 0.98 0.09 1.36 0.14 0.31 0.04 0.63 0.12 0.93 0.24 0.44 0.16 0.83 0.42 1.16 0.74 0.31 0.35 0.56 0.74 0.75 1.17 0.12 0.3 0.19 0.61 0.23 0.93 0.1 0.44 0.15 0.9 0.14 1.36 0.05 0.51 0.05 0.93 0.05 1.26v4.68z" />
  </svg>
);

export default InstagramSVG;
