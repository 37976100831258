// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Card from '../components/Card';
import Dropdown from '../components/Dropdown';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';

// Utils
import { getBlogPosts } from '../utils/wordpressApi';

class Blog extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      pathname,
      categories: [],
      filtered: {},
      filters: {
        category: null,
        search: '',
      },
      metatags: {},
      posts: [],
    };

    this.filterList = this.filterList.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;
    const { pathname } = this.state;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getBlogPosts();

    if (this._isMounted && !!data) {
      const { metatags, posts } = data;
      let postsList = [];

      if (Array.isArray(posts) && posts.length > 0) {
        postsList = posts.map(
          ({ category, date, id, slug, thumbnail, title }) => {
            return {
              category,
              date,
              id,
              link: `${pathname}/${slug}`,
              slug,
              thumbnail,
              title,
            };
          }
        );
      }

      this.setState({
        metatags,
        posts: postsList,
      });

      /**
       * Get distinct categories
       */
      const categories = posts
        .filter(
          (post, index, self) =>
            index ===
            self.findIndex(t => t.category.slug === post.category.slug)
        )
        .map(post => {
          return post.category;
        });

      this.setState({
        categories,
      });

      // Set list without filters
      this.filterList();
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Filter posts
   */
  filterList(type = null, slug = null) {
    const { filters, posts } = this.state;
    const applyFilters = { ...filters };
    const rules = [];

    if (type) {
      applyFilters[type] = slug ? slug.toLowerCase() : slug;
    }

    Object.keys(applyFilters).map(filter => {
      if (applyFilters[filter] !== null && applyFilters[filter] !== '') {
        if (filter !== 'search') {
          rules.push(item => item[filter].slug === applyFilters[filter]);
        } else if (applyFilters.search.length >= 3) {
          rules.push(
            item => item.title.toLowerCase().search(applyFilters[filter]) > -1
          );
        }
      }
      return true;
    });

    const list = posts
      .filter(rule => rules.every(func => func(rule)))
      .reduce((rv, x) => {
        (rv[x.type] = rv[x.type] || []).push(x);
        return rv;
      }, {});

    this.setState({
      filtered: list,
      filters: applyFilters,
    });

    return list;
  }

  render() {
    const { categories, filters, filtered, metatags } = this.state;

    return (
      <Layout className="blog-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h1 className="hero__title">{i18n.t('blog').title}</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-page__list">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 offset-lg-1">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                        <SearchInput
                          id="filter-search"
                          name="filter-search"
                          value={filters.search}
                          handleChange={e => {
                            this.filterList('search', e.target.value);
                          }}
                          handleClick={() => {
                            this.filterList('search', filters.search);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card-filters">
                      <div className="card-filters__container">
                        <div className="row">
                          <div className="card-filters__filter card-filters__filter--category col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                            <Dropdown
                              id="categoryDropdown"
                              onClick={this.filterList}
                              options={categories}
                              placeholder={i18n.t('menu').categories}
                              type="category"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!Object.keys(filtered).length && (
              <div className="card-list row">
                <div className="empty col-12">{i18n.t('result').no_result}</div>
              </div>
            )}

            {!!Object.keys(filtered).length && (
              <div className="card-list row">
                {Object.entries(filtered).map(([key, obj]) => {
                  return (
                    <article key={key} className="menu-list__sub-list col-12">
                      <div className="card-list row">
                        {obj.map(
                          ({
                            category,
                            date,
                            link,
                            slug,
                            thumbnail,
                            title,
                          }) => {
                            return (
                              <Card
                                key={slug}
                                title={title}
                                image={{
                                  sizes: {
                                    medium: thumbnail.sizes.landscapeWideMd,
                                    small: thumbnail.sizes.landscapeWideSm,
                                  },
                                  title: thumbnail.title,
                                }}
                                caption={`${date} - ${category.name}`}
                                slug={slug}
                                to={link}
                                hover={i18n.t('see_more')}
                              />
                            );
                          }
                        )}
                      </div>
                    </article>
                  );
                })}
              </div>
            )}
          </div>
        </section>
      </Layout>
    );
  }
}

Blog.contextType = AppContext;

export default withTranslation()(Blog);
