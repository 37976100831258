// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import CateringForm from '../components/CateringForm';
import Layout from '../components/Layout';

// Utils
import { getCatering } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';

class Catering extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      thumbnail: { sizes: { large: '', xl: '' }, title: '' },
      metatags: {},
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getCatering();

    if (this._isMounted && !!data) {
      const { content, metatags, thumbnail, title } = data;

      this.setState(prevState => ({
        ...prevState,
        content,
        metatags,
        thumbnail,
        title,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { content, metatags, thumbnail, title } = this.state;

    return (
      <Layout className="catering-page" metatags={metatags}>   
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1"> 
              <section className="hero">
                <h1 className="hero__title">{i18n.t('catering').title}</h1>
              </section>

              <div className="catering-page__content--text gutenberg-wrapper">
                {renderHtml(content)}
              </div>
            </div>
          </div>
        </div>

        <CateringForm title="Contactez-nous" />
      </Layout>
    );
  }
}

Catering.contextType = AppContext;

export default withTranslation()(Catering);
