import React from 'react';
import PropTypes from 'prop-types';

// i18n
import i18n from '../../i18n';

// Configuration
import { globals } from '../../config';

const FileInput = ({ error, handleChange, id, name, label, button, value }) => {
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-lg-6">
          <label
            htmlFor={id}
            className={`file-label${error ? ' is-invalid' : ''}`}
            data-content={
              value instanceof File
                ? `${value.name.substring(0, 15)}...`
                : button
            }
          >
            {label}
          </label>
          <input
            className="form-file"
            id={id}
            name={name}
            type="file"
            onChange={handleChange}
            accept={globals.forms.file.mime}
          />
        </div>
        <div className="file-label__caption col-lg-6">
          {i18n.t('forms').max_size}
          <br />
          {i18n.t('forms').accepted_files}
        </div>
      </div>
    </div>
  );
};

FileInput.propTypes = {
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  button: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(File)]),
};

FileInput.defaultProps = {
  error: false,
  label: '',
  button: '',
  value: {},
};

export default FileInput;
