// Configuration
import { globals } from '../config';

/*
 * Validate field rules
 */
export default (value, rules = []) => {
  const isRequired = rules.indexOf('required') > -1;

  if (isRequired) {
    if (
      value === null || 
      value.length <= 0 || 
      value === false || 
      (typeof value === 'object' && !value.toString())
    ) 
      return false;
  }

  const errors = rules
    .map(rule => {
      if (rule === 'email') {
        return new RegExp(
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
        ).test(String(value).toLowerCase());
      }

      if (rule === 'number') {
        if (value.length === 0) {
          return true;
        }
        return new RegExp(/^\d+$/).test(value);
      }

      if (rule === 'day') {
        return new RegExp(/^\d+$/).test(value) && value >= 1 && value <= 31;
      }

      if (rule === 'month') {
        return new RegExp(/^\d+$/).test(value) && value >= 1 && value <= 12;
      }

      if (rule === 'phone') {
        return new RegExp(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        ).test(value);
      }

      if (rule === 'postalCode') {
        return new RegExp(/^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/).test(
          value
        );
      }

      if (rule === 'province') {
        return globals.forms.provinces.includes(value);
      }

      if (rule === 'file') {
        if (value instanceof File) {
          if (
            globals.forms.file.mime.every(type => value.type !== type) ||
            (value.size / 1024 / 1024).toFixed(4) > globals.forms.file.max_size
          ) {
            return false;
          }
        }
      }

      if (rule === 'notNull') {
        return value !== null || value > 0;
      }

      return true;
    })
    .filter(message => message !== true);

  return errors.length <= 0 ? true : false;
};
