import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import fr from './locales/fr.json';

i18n.use(initReactI18next).init({
  resources: {
    fr,
  },
  debug: process.env.NODE_ENV !== 'production',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  returnEmptyString: false,
  returnObjects: true,
  returnNull: false,
  lng: 'fr',
  fallbackLng: 'fr',
  react: {
    wait: true,
  },
});

export default i18n;

export const AVAILABLE_LOCALES = ['fr'];
export const DEFAULT_LOCALE = 'fr';
