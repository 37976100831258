/**
 * Globals
 */
export const globals = {
  forms: {
    file: {
      max_size: 5242880, // Mo
      mime: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
    },
    provinces: [
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NT',
      'NS',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT',
    ],
  },
};

/**
 * Settings
 */
export const settings = {
  metatags: {
    description: '',
    language: 'fr-CA',
    opengraph: {
      description: '',
      image: {
        id: 0,
        image: '',
      },
      title: 'District Gourmet - Le QG Sainte-Foy',
    },
    robots: '',
    title: 'District Gourmet - Le QG Sainte-Foy',
    twitter: {
      card_type: '',
      description: '',
      image: {
        id: 0,
        image: '',
      },
      title: 'District Gourmet - Le QG Sainte-Foy',
    },
    site_name: 'District Gourmet - Le QG Sainte-Foy',
    type: 'website',
  },
};
