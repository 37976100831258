export default function(dataLayers = {}) {
  if (window === 'undefined') return false;

  return new Promise(resolve => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(dataLayers);
    console.log(dataLayers);

    resolve();
  });
}
