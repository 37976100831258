// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

// Components
import Button from '../Button';
import Input from '../Input';
import Textarea from '../Textarea';

// i18n
import i18n from '../../i18n';

// Utilities
import validateField from '../../utils/validateField';
import { postContactForm, verifyReCAPTCHA } from '../../utils/wordpressApi';

// ReCAPTCHA
const recaptchaRef = React.createRef();

class EventForm extends Component {
  constructor(props) {
    super(props);
    const {eventName,email} = this.props;

    this.state = {
      fields: {
        lastname: {
          value: '',
          type: 'text',
          label: i18n.t('forms').last_name,
          id: 'lastname',
          name: 'lastname',
          rules: ['required'],
          error: false,
        },
        firstname: {
          value: '',
          type: 'text',
          label: i18n.t('forms').first_name,
          id: 'firstname',
          name: 'firstname',
          rules: ['required'],
          error: false,
        },
        email: {
          value: '',
          type: 'email',
          label: i18n.t('forms').email,
          id: 'email',
          name: 'email',
          rules: ['required', 'email'],
          error: false,
        },
        phone: {
          value: '',
          type: 'text',
          label: i18n.t('forms').phone,
          id: 'phone',
          name: 'phone',
          rules: ['required', 'phone'],
          error: false,
        },
        message: {
          value: '',
          label: i18n.t('forms').message,
          id: 'message',
          name: 'message',
          rules: ['required'],
          error: false,
        },
        event: {
          value: eventName,
          type: 'text',
          id: 'event',
          name: 'event',
          error: false,
        },
        emailsend: {
          value: email,
          type: 'text',
          id: 'emailsend',
          name: 'emailsend',
          error: false,
        },
      },
      status: {
        code: '',
        message: '',
      },
      humanRecognition: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Form submission
   */
  handleFormSubmit = e => {
    e.preventDefault();

    const { fields } = this.state;
    const errors = [];

    /**
     * Validate fields defined in state
     */
    Object.keys(fields).map(name => {
      const field = fields[name];

      if (!validateField(field.value, field.rules)) {
        errors.push(name);

        this.setState(prevState => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [name]: {
              ...prevState.fields[name],
              error: true,
            },
          },
        }));
      }

      return true;
    });

    if (errors.length === 0) {
      const formData = new FormData();

      Object.keys(fields).map(name => {
        formData.append(name, fields[name].value);
        return true;
      });

      // Post datas
      Promise.all([
        postContactForm(process.env.CF7_REST_API_EVENT_ID, formData),
      ])
        .then(response => {
          this.setState({
            status: {
              code: response[0].status,
              message: response[0].message,
            },
          });

          if (response[0].status === 'mail_sent') {
            fbq('track', 'Contact', {
              content_type: 'telephone-formulaire',
            });

            this.clearForm();
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  /**
   * Update state on field changes
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: {
          ...prevState.fields[name],
          value,
          error: false,
        },
      },
    }));
  };

  /**
   * Clean form fields
   */
  clearForm = () => {
    const { fields } = this.state;

    /**
     * Validate fields defined in state
     */
    Object.keys(fields).map(name => {
      this.setState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [name]: {
            ...prevState.fields[name],
            value: '',
          },
        },
      }));

      return true;
    });
  };

  /**
   * Update reCAPTCHA status
   */
  onReCAPTCHAChange = token => {
    if (!token) {
      this.setState({ humanRecognition: false });
      recaptchaRef.current.execute();
    }

    Promise.all([verifyReCAPTCHA(token)])
      .then(response => {
        this.setState({ humanRecognition: response });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  componentDidMount() {
    recaptchaRef.current.execute();
  }

  render() {
    const { eventName } = this.props;
    const {
      fields: { lastname, firstname, email, phone, message, event, emailsend },
      status,
      humanRecognition,
    } = this.state;

    let formStatusClasses = 'form__container--status col-12';
    if (status.code !== 'mail_sent') formStatusClasses += ` error`;
    if (status.code === 'mail_sent') formStatusClasses += ` success`;

    

    return (
      <>
        <section className="form">
          <div className="form__container">
            <div className="row">
              <div className="col-xl-10 mx-auto">
                <form className="row">
                  <div className="col-lg-6">
                    <Input
                      error={lastname.error}
                      type={lastname.type}
                      label={lastname.label}
                      id={lastname.id}
                      name={lastname.name}
                      value={lastname.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={firstname.error}
                      type={firstname.type}
                      label={firstname.label}
                      id={firstname.id}
                      name={firstname.name}
                      value={firstname.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={email.error}
                      type={email.type}
                      label={email.label}
                      id={email.id}
                      name={email.name}
                      value={email.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={phone.error}
                      type={phone.type}
                      label={phone.label}
                      id={phone.id}
                      name={phone.name}
                      value={phone.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <Textarea
                      error={message.error}
                      label={message.label}
                      id={message.id}
                      name={message.name}
                      value={message.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <Button
                      onClick={this.handleFormSubmit}
                      type="submit"
                      className="btn-outline"
                      disabled={!humanRecognition}
                    >
                      {i18n.t('forms').submit}
                    </Button>
                  </div>
                  {!!status.message && (
                    <div className={formStatusClasses}>{status.message}</div>
                  )}
                  {process.env.NODE_ENV !== 'production' && humanRecognition && (
                    <div className="col-12 mt-4">
                      <small>Verified by reCAPTCHA</small>
                    </div>
                  )}
                  <Input
                    error={event.error}
                    type={event.type}
                    id={event.id}
                    name={event.name}
                    value={event.value}
                    handleChange={this.handleChange}
                    hidden = "true"
                  />
                  <Input
                    error={emailsend.error}
                    type={emailsend.type}
                    id={emailsend.id}
                    name={emailsend.name}
                    value={emailsend.value}
                    handleChange={this.handleChange}
                    hidden = "true"
                  />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    onChange={this.onReCAPTCHAChange}
                    size="invisible"
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

EventForm.propTypes = {
  eventName: PropTypes.string,
};

export default EventForm;
