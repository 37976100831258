/**
 * DatePicker documentation
 * https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
 */

// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import ReCAPTCHA from 'react-google-recaptcha';

// Components
import fr from 'date-fns/locale/fr'; // register it with the name you want
import { format, add } from 'date-fns';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Input from '../Input';
import Textarea from '../Textarea';

// i18n
import i18n from '../../i18n';

// Utilities
import validateField from '../../utils/validateField';
import { postContactForm, verifyReCAPTCHA } from '../../utils/wordpressApi';

// Set locale
registerLocale('fr', fr);

// ReCAPTCHA
const recaptchaRef = React.createRef();

class CateringForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        lastname: {
          value: '',
          type: 'text',
          label: i18n.t('forms').last_name,
          id: 'lastname',
          name: 'lastname',
          rules: ['required'],
          error: false,
        },
        firstname: {
          value: '',
          type: 'text',
          label: i18n.t('forms').first_name,
          id: 'firstname',
          name: 'firstname',
          rules: ['required'],
          error: false,
        },
        organization: {
          value: '',
          type: 'text',
          label: i18n.t('forms').organization,
          id: 'organization',
          name: 'organization',
          rules: ['required'],
          error: false,
        },
        email: {
          value: '',
          type: 'email',
          label: i18n.t('forms').email,
          id: 'email',
          name: 'email',
          rules: ['required', 'email'],
          error: false,
        },
        phone: {
          value: '',
          type: 'text',
          label: i18n.t('forms').phone,
          id: 'phone',
          name: 'phone',
          rules: ['required', 'phone'],
          error: false,
        },
        date: {
          value: null,
          type: 'text',
          label: i18n.t('forms').date,
          id: 'date',
          name: 'date',
          rules: ['required'],
          error: false,
        },
        guests: {
          value: '',
          type: 'number',
          label: i18n.t('forms').guests,
          id: 'guests',
          name: 'guests',
          rules: ['required', 'number'],
          error: false,
        },
        message: {
          value: '',
          label: i18n.t('forms').message,
          id: 'message',
          name: 'message',
          rules: ['required'],
          error: false,
        },
        consent: {
          value: '',
          label: i18n.t('forms').consent,
          id: 'consent',
          name: 'consent',
          rules: ['required'],
          error: false,
        },
      },
      status: {
        code: '',
        message: '',
      },
      humanRecognition: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Update reCAPTCHA status
   */
  onReCAPTCHAChange = token => {
    if (!token) {
      this.setState({ humanRecognition: false });
      recaptchaRef.current.execute();
    }

    Promise.all([verifyReCAPTCHA(token)])
      .then(response => {
        this.setState({ humanRecognition: response });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  componentDidMount() {
    recaptchaRef.current.execute();
  }

  /**
   * Form submit logic
   */
  handleFormSubmit = e => {
    e.preventDefault();

    const { fields } = this.state;
    const errors = [];

    /**
     * Validate fields defined in state
     */
    Object.keys(fields).map(name => {
      const field = fields[name];

      if (!validateField(field.value, field.rules)) {
        errors.push(name);

        this.setState(prevState => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [name]: {
              ...prevState.fields[name],
              error: true,
            },
          },
        }));
      }

      return true;
    });

    if (errors.length === 0) {
      const formData = new FormData();
      Object.keys(fields).map(name => {
        formData.append(name, fields[name].value);
        return true;
      });

      // Post datas
      Promise.all([
        postContactForm(process.env.CF7_REST_API_CATERING_ID, formData),
      ])
        .then(response => {
          this.setState({
            status: {
              code: response[0].status,
              message: response[0].message,
            },
          });

          if (response[0].status === 'mail_sent') {
            this.clearForm();
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  /**
   * Update state on change
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: {
          ...prevState.fields[name],
          value,
          error: false,
        },
      },
    }));
  };

  /**
   * Update state on checkbox field changes
   */
  handleCheckboxChange = e => {
    const isChecked = e.target.checked;
    const { name } = e.target;

    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: {
          ...prevState.fields[name],
          value: isChecked,
          error: false,
        },
      },
    }));
  };

  /**
   * Update date
   */
  handleDateChange = value => {
    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        date: {
          ...prevState.fields.date,
          value,
          error: false,
        },
      },
    }));
  };

  clearForm = () => {
    const { fields } = this.state;

    /**
     * Validate fields defined in state
     */
    Object.keys(fields).map(name => {
      this.setState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [name]: {
            ...prevState.fields[name],
            value: '',
            error: false,
          },
        },
      }));

      return true;
    });
  };

  render() {
    const { title } = this.props;
    const {
      fields: {
        lastname,
        firstname,
        organization,
        email,
        phone,
        date,
        guests,
        message,
        consent,
      },
      status,
      humanRecognition,
    } = this.state;

    let formStatusClasses = 'form__container--status col-12';
    if (status.code !== 'mail_sent') formStatusClasses += ` error`;
    if (status.code === 'mail_sent') formStatusClasses += ` success`;

    return (
      <>
        <section className="form">
          <div className="form__container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <h2 className="form__title">{title}</h2>
                <form className="row">
                  <div className="col-lg-6">
                    <Input
                      error={lastname.error}
                      type={lastname.type}
                      label={lastname.label}
                      id={lastname.id}
                      name={lastname.name}
                      value={lastname.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={firstname.error}
                      type={firstname.type}
                      label={firstname.label}
                      id={firstname.id}
                      name={firstname.name}
                      value={firstname.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <Input
                      error={organization.error}
                      type={organization.type}
                      label={organization.label}
                      id={organization.id}
                      name={organization.name}
                      value={organization.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={email.error}
                      type={email.type}
                      label={email.label}
                      id={email.id}
                      name={email.name}
                      value={email.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={phone.error}
                      type={phone.type}
                      label={phone.label}
                      id={phone.id}
                      name={phone.name}
                      value={phone.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <DatePicker
                        id={date.id}
                        value={
                          date.value
                            ? format(date.value, 'EEEE dd MMMM yyyy', {
                                locale: fr,
                              })
                            : ''
                        }
                        minDate={new Date()}
                        maxDate={add(new Date(), { months: 3 })}
                        className={`form-input${
                          date.error ? ' is-invalid' : ''
                        }`}
                        selected={date.value}
                        locale="fr"
                        placeholderText={date.label}
                        onChange={this.handleDateChange}
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Input
                      error={guests.error}
                      type={guests.type}
                      label={guests.label}
                      id={guests.id}
                      name={guests.name}
                      value={guests.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <Textarea
                      error={message.error}
                      label={message.label}
                      id={message.id}
                      name={message.name}
                      value={message.value}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="form__container--notice col-lg-12">
                    {i18n.t('catering').allergies}
                  </div>
                  <div className="col-12">
                    <Checkbox
                      checked={!!consent.value}
                      error={consent.error}
                      id={consent.id}
                      label={consent.label}
                      name={consent.name}
                      onChange={this.handleCheckboxChange}
                    />
                  </div>
                  <div className="col-12 form__btn-submit">
                    <Button
                      onClick={this.handleFormSubmit}
                      type="submit"
                      className="btn-outline"
                      // disabled={!humanRecognition}
                    >
                      {i18n.t('forms').submit}
                    </Button>
                  </div>
                  <div className={formStatusClasses}>{status.message}</div>
                  {process.env.NODE_ENV !== 'production' && humanRecognition && (
                    <div className="col-12 mt-4">
                      <small>Verified by reCAPTCHA</small>
                    </div>
                  )}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    onChange={this.onReCAPTCHAChange}
                    size="invisible"
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

CateringForm.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CateringForm;
