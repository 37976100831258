// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Card from '../components/Card';

// Utils
import { getPromotions } from '../utils/wordpressApi';
import { convertDate } from '../utils/date';

class Promotion extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      metatags: {},
      pathname,
      promotions: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;
    const { pathname } = this.state;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getPromotions();

    if (this._isMounted && !!data) {
      const { metatags, promotions } = data;
      let promotionsList = [];

      if (Array.isArray(promotions) && promotions.length > 0) {
        promotionsList = promotions.map(
          ({ beginDate, endDate, id, slug, thumbnail, title }) => {
            return {
              beginDate: beginDate
                ? convertDate(beginDate, 'dd MMMM yyyy')
                : null,
              endDate: endDate ? convertDate(endDate, 'dd MMMM yyyy') : null,
              id,
              link: `${pathname}/${slug}`,
              slug,
              thumbnail,
              title,
            };
          }
        );
      }

      this.setState({
        metatags,
        promotions: promotionsList,
      });
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { metatags, promotions } = this.state;

    return (
      <Layout className="promotion-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h1 className="hero__title">{i18n.t('offers').title}</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="promotion-page__list">
          <div className="container">
            <div className="row">
              {!!promotions &&
                !!promotions.length &&
                promotions.map(
                  ({ beginDate, endDate, link, slug, thumbnail, title }) => {
                    let caption = '';

                    if (!!beginDate && !!endDate) {
                      caption = `${beginDate} - ${endDate}`;
                    }

                    if (!beginDate && !!endDate) {
                      caption = `${i18n.t('offers').until} ${endDate}`;
                    }

                    return (
                      <Card
                        key={slug}
                        title={title}
                        image={{
                          sizes: {
                            medium: thumbnail.sizes.landscapeWideMd,
                            small: thumbnail.sizes.landscapeWideSm,
                          },
                          title: thumbnail.title,
                        }}
                        caption={caption}
                        slug={slug}
                        to={link}
                        hover={i18n.t('see_more')}
                      />
                    );
                  }
                )}
              {(!promotions || !promotions.length) && (
                <div className="col-lg-10 offset-lg-1">
                  {i18n.t('offers').no_results}
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Promotion.contextType = AppContext;

export default withTranslation()(Promotion);
