// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import fr from 'date-fns/locale/fr';
import { format, parseISO } from 'date-fns';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Button from '../components/Button';
import ContestForm from '../components/ContestForm';

// Utils
import { getContest } from '../utils/wordpressApi';
import getLocalizedPath from '../utils/getLocalizedPath';
import { renderHtml } from '../utils/renderHtml';

class ContestSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      beginDate: '',
      content: '',
      endDate: '',
      metatags: {},
      redirect: null,
      slug: props.match.params.slug,
      thumbnail: {
        sizes: { large: '', xl: '' },
        title: '',
      },
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getContest(slug);

    if (this._isMounted) {
      if (data) {
        const {
          beginDate,
          content,
          endDate,
          metatags,
          thumbnail,
          title,
        } = data;

        this.setState(prevState => ({
          ...prevState,
          beginDate: format(parseISO(beginDate), 'dd MMMM yyyy', {
            locale: fr,
          }),
          content,
          endDate: format(parseISO(endDate), 'dd MMMM yyyy', {
            locale: fr,
          }),
          metatags,
          thumbnail,
          title,
        }));
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      beginDate,
      content,
      endDate,
      metatags,
      redirect,
      thumbnail,
      title,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    return (
      <Layout className="contest-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <p className="date">
                  {`${i18n.t('offers').from} ${beginDate} ${
                    i18n.t('offers').to
                  } ${endDate}`}
                </p>
                <h1 className="hero__title">{title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="contest-page__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                {!!thumbnail.sizes.landscapeSm && (
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={thumbnail.sizes.landscapeWideLg}
                    />
                    <img
                      src={thumbnail.sizes.landscapeSm}
                      alt={thumbnail.title}
                      title={thumbnail.title}
                    />
                  </picture>
                )}
                <div className="contest-page__content--text">
                  {renderHtml(content)}
                </div>
                <div className="contest-page__content--cta">
                  <Button to={getLocalizedPath('contest')}>
                    {i18n.t('back')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ContestForm title={i18n.t('contests').register} contest={title} />
      </Layout>
    );
  }
}

ContestSingle.contextType = AppContext;

export default withTranslation()(ContestSingle);
