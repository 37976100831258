// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Button from '../components/Button';
import Card from '../components/Card';
// import CtaButtons from '../components/CtaButtons';
import Dropdown from '../components/Dropdown';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';
// import SectionTitle from '../components/SectionTitle';

// Utils
import { getEvents } from '../utils/wordpressApi';
import { truncateString } from '../utils/renderHtml';
import { convertDate } from '../utils/date';
import { arrayOrderDate } from '../utils/tools';
import getLocalizedPath from '../utils/getLocalizedPath';

class Event extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      pathname,
      months: [],
      filtered: {},
      filters: {
        month: null,
        search: '',
      },
      events: [],
      metatags: {},
      rooms: {
        title: '',
        text: '',
      },
    };

    this.filterList = this.filterList.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;
    const { pathname } = this.state;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getEvents();

    if (this._isMounted && !!data) {
      const {
        events,
        metatags,
        settings: { roomsTitle, roomsText },
      } = data;
      let eventsList = [];

      if (Array.isArray(events) && events.length > 0) {
        eventsList = events.map(
          ({ beginDate, endDate, id, month, slug, thumbnail, title, beginTime }) => {
            return {
              beginDate,
              endDate,
              id,
              link: `${pathname}/${slug}`,
              month,
              slug,
              thumbnail,
              title,
              beginTime,
            };
          }
        );

        /**
         * Get distinct months
         */
        const months = events
          .filter(
            (event, index, self) =>
              index === self.findIndex(t => t.month === event.month)
          )
          .map(event => {
            return {
              date: convertDate(`${event.month}-01`, 'yyyy-MM'),
              name: convertDate(`${event.month}-01`, 'MMMM yyyy'),
            };
          });

        this.setState({
          events: eventsList,
          metatags,
          months: arrayOrderDate(months),
        });
      }

      this.setState({
        rooms: {
          title: roomsTitle,
          text: roomsText,
        },
      });

      // Set list without filters
      this.filterList();
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Filter events
   */
  filterList(type = null, slug = null) {
    const { filters, events } = this.state;
    const applyFilters = { ...filters };
    const rules = [];

    if (type) {
      applyFilters[type] = slug ? slug.toLowerCase() : slug;
    }

    Object.keys(applyFilters).map(filter => {
      if (applyFilters[filter] !== null && applyFilters[filter] !== '') {
        if (filter !== 'search') {
          rules.push(item => item[filter] === applyFilters[filter]);
        } else if (applyFilters.search.length >= 3) {
          rules.push(
            item => item.title.toLowerCase().search(applyFilters[filter]) > -1
          );
        }
      }
      return true;
    });

    const list = events
      .filter(rule => rules.every(func => func(rule)))
      .reduce((rv, x) => {
        (rv[x.type] = rv[x.type] || []).push(x);
        return rv;
      }, {});

    this.setState({
      filtered: list,
      filters: applyFilters,
    });

    return list;
  }

  renderDate(beginDate, endDate) {
    if (!!endDate && endDate !== beginDate) {
      return `${convertDate(beginDate)} - ${convertDate(endDate)}`;
    }

    return convertDate(beginDate);
  }

  render() {
    const { metatags, months, filters, filtered } = this.state;

    return (
      <Layout className="event-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 offset-lg-1">
                <h1 className="hero__title">{i18n.t('events').title}</h1>
              </div>
              <div className="hero__cta d-none justify-content-end d-lg-flex col-lg-5 offset-lg-1">
                <Button outline={false} to={getLocalizedPath('catering')}>
                  {i18n.t('rooms').rent}
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className="event-page__list">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 offset-lg-1">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                        <SearchInput
                          id="filter-search"
                          name="filter-search"
                          value={filters.search}
                          handleChange={e => {
                            this.filterList('search', e.target.value);
                          }}
                          handleClick={() => {
                            this.filterList('search', filters.search);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card-filters">
                      <div className="card-filters__container">
                        <div className="row">
                          <div className="card-filters__filter card-filters__filter--month col-md-10 offset-md-1 col-lg-12 offset-lg-0">
                            <Dropdown
                              id="monthDropdown"
                              onClick={this.filterList}
                              options={months.map(
                                ({ date: slug, ...rest }) => ({ slug, ...rest })
                              )}
                              placeholder={i18n.t('events').filter}
                              type="month"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 card-cta d-lg-none">
                    <Button
                      outline={false}
                      to={getLocalizedPath('catering')}
                    >
                      {i18n.t('rooms').rent}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {!Object.keys(filtered).length && (
              <div className="card-list row">
                <div className="empty col-12">{i18n.t('result').no_result}</div>
              </div>
            )}

            {!!Object.keys(filtered).length && (
              <div className="card-list row">
                {Object.entries(filtered).map(([key, obj]) => {
                  return (
                    <article key={key} className="menu-list__sub-list col-12">
                      <div className="card-list row">
                        {obj.map(
                          ({
                            beginDate,
                            endDate,
                            link,
                            slug,
                            thumbnail,
                            title,
                            beginTime,
                          }) => {
                            return (
                              <Card
                                key={slug}
                                title={truncateString(title, 45)}
                                image={{
                                  sizes: {
                                    medium: thumbnail.sizes.landscapeWideMd,
                                    small: thumbnail.sizes.landscapeWideSm,
                                  },
                                  title: thumbnail.title,
                                }}
                                caption={this.renderDate(beginDate, endDate)}
                                slug={slug}
                                to={link}
                                hover={i18n.t('see_more')}
                                beginTime={beginTime}
                              />
                            );
                          }
                        )}
                      </div>
                    </article>
                  );
                })}
              </div>
            )}
          </div>
        </section>
        {/*
        <SectionTitle
          introduction={rooms.text}
          surtitle={i18n.t('rooms').our_rooms}
          title={rooms.title}
          centered={false}
        />

        <section className="event-page__rooms">
          <div className="container">
            <div className="event-page__rooms--plan col-12">PLAN</div>
            <div className="event-page__rooms--plan col-12">PLAN</div>
          </div>
        </section>
        <CtaButtons buttons={['rent']} />
        */}
      </Layout>
    );
  }
}

Event.contextType = AppContext;

export default withTranslation()(Event);
