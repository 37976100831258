import React from 'react';

const LogotypeSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 59">
    <path d="M60.7 58.9c2.9 0 5.3-2.4 5.3-5.3 0-1.5 0-3 0-4.5h0c0-1.5 0-3 0-4.4 0-1.2-0.3-2.3-1-3.2 -1.6-2.2-4.3-2.6-6.8-1.9 -2.2 0.7-3.7 2.8-3.6 5.2 0 2.7 0 5.5 0 8.2C54.5 56.8 56.8 59 60.7 58.9zM58 44.6c0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.5 0 2.9 0 4.4l0 0c0 1.4 0 2.8 0 4.3 0 0.1 0 0.2 0 0.3 -0.1 1.2-1.1 2.2-2.3 2.1 -1.2-0.1-2.2-1.1-2.1-2.3C58 50.4 58 47.5 58 44.6z" />
    <path d="M125 0.6c-1 0-2 0-3 0 -1.4 0-1.8 0.4-1.8 1.8 0 5.3 0 10.6 0 15.8l0 0c0 2.1 0 4.1 0 6.2 0 3.3 0 6.6 0 10 0 1.2 0.5 1.6 1.6 1.6 1.1 0 2.2 0 3.3 0 0.7 0.1 1.4-0.5 1.4-1.2 0-0.1 0-0.1 0-0.2 0-0.3 0-0.6 0-0.9 0-9.4 0-18.8 0-28.2 0-1.1 0-2.3 0-3.4C126.6 1.1 126.1 0.6 125 0.6z" />
    <path d="M107.7 58.7c0.7 0 1-0.3 1-1 0-1.3 0-2.6 0-3.9 0-4.4 0-8.7 0-13.1 0-1-0.1-1.1-1.1-1.1 -0.8 0-1.6 0-2.3 0 -0.7-0.1-1.3 0.3-1.4 1 0 0 0 0 0 0 -0.2 0.8-0.5 1.5-0.7 2.3 -0.5 1.7-1 3.3-1.6 5.2 -0.2-0.5-0.3-0.7-0.3-1l0 0c-0.7-2.2-1.4-4.4-2.1-6.7 -0.1-0.6-0.6-0.9-1.2-0.9 -0.8 0-1.6 0-2.4 0 -1.1 0-1.2 0.1-1.2 1.3 0 5.2 0 10.5 0 15.7 0 2.4-0.1 2.2 2.2 2.2 1 0 1.2-0.2 1.2-1.2 0-3.2 0-6.3 0-9.5v-0.8c0.2 0.2 0.3 0.4 0.4 0.7 0.5 1.7 1 3.3 1.5 5 0.1 0.6 0.6 1 1.2 0.9 0.3 0 0.5 0 0.8 0 1.4 0 1.5-0.1 1.9-1.4 0.5-1.7 1.1-3.5 1.6-5.2 0.1 0.1 0.2 0.3 0.2 0.5 0 2.9 0 5.8 0 8.7C105.3 59 105.1 58.6 107.7 58.7z" />
    <path d="M127.7 58.7c1.1 0 1.2-0.1 1.2-1.3 0-4.6 0-9.2 0-13.8v-1c0.4 0 0.8 0 1.1 0l0 0c2.1 0 2.1 0 2-2 0-0.9-0.2-1.1-1.2-1.1 -2.1 0-4.2 0-6.4 0 -2.2 0-2.2 0-2.1 2.2 0 0.7 0.3 0.9 0.9 0.9 0.7 0 1.3 0 2.1 0 0 0.3 0 0.6 0 0.9 0 4.3 0 8.7 0 13C125.4 58.7 125.4 58.7 127.7 58.7z" />
    <path d="M95.5 5.1c0-0.1 0-0.2 0-0.4 0-0.8 0-1.7 0-2.5 0-1.4-0.4-1.8-1.8-1.8 -2.1 0-4.2 0-6.3 0 -2.5 0-5.1 0-7.6 0 -1.7 0-2 0.3-2 2 0 0.7 0 1.4 0 2.2 0 1.3 0.4 1.7 1.7 1.7 1.3 0 2.5 0 3.9 0l0 1c0 5.7 0 11.5 0 17.2 0 3.2 0 6.5 0 9.7 0 1.2 0.5 1.7 1.7 1.7 0.9 0 1.8 0 2.7 0 1.6 0 2-0.3 2-2 0-8.9 0-17.9 0-26.8 0-0.7 0.2-0.9 0.8-0.9 1.2 0.1 2.3 0 3.5 0C94.7 6.4 95.4 5.9 95.5 5.1z" />
    <path d="M85.7 51.1c0.5-0.1 0.8 0.2 1 0.7 0.5 1.5 0.9 3 1.4 4.4 0.9 2.8 0.4 2.4 3.6 2.5 0.8 0 0.9-0.2 0.7-1 -0.8-2.3-1.6-4.7-2.4-7 0 0 0 0 0 0 -0.2-0.4-0.1-0.9 0.3-1.1 1-0.7 1.5-1.8 1.5-3 0-1.1 0-2.3 0-3.4 0.1-1.2-0.6-2.3-1.6-2.9 -0.8-0.4-1.7-0.7-2.6-0.7 -1.6-0.1-3.3 0-4.9 0 -1.1 0-1.3 0.2-1.3 1.3 0 2.8 0 5.5 0 8.3l0 0c0 2.8 0 5.6 0 8.5 0 1 0.2 1.1 1.1 1.1 0.5 0 1 0 1.5 0 0.7 0 1.1-0.3 1.1-1.1 0-1.9 0-3.8 0-5.6C85.1 51.5 85.1 51.1 85.7 51.1zM84.8 46c0-0.4 0-0.7 0-1.1l0 0c0-0.7 0-1.3 0-2 0-0.4 0.2-0.6 0.6-0.6 0.4 0 0.8 0 1.1 0 0.1 0 0.2 0 0.3 0 0.8 0.1 1.4 0.8 1.4 1.7 0 0.7 0 1.4 0 2.2 0 0 0 0.1 0 0.1 0 0.8-0.7 1.5-1.5 1.5C84.9 47.9 84.8 47.8 84.8 46z" />
    <path d="M79.4 47.2c0-0.4 0-0.8 0-1.2 0-1.8 0-3.6 0-5.4 0-0.9-0.3-1.1-1.2-1.1 -1.9-0.1-2.2 0.1-2.3 1 0 0.3 0 0.7 0 1 0 3.8 0 7.5 0 11.3 0 0.4 0 0.7-0.1 1.1 -0.4 1.2-1.6 1.8-2.8 1.5 -1.1-0.2-1.9-1.1-1.8-2.2 0-3.9 0-7.9-0.1-11.8 0-1.8 0-1.8-1.8-1.8 -1.4 0-1.6 0.2-1.6 1.6 0 4 0 8 0 12 0 0.4 0 0.8 0.1 1.2 0.5 2.6 2.9 4.5 5.5 4.5 3.1 0.3 5.9-2 6.2-5.1 0-0.3 0-0.7 0-1C79.2 50.9 79.3 49.1 79.4 47.2L79.4 47.2z" />
    <path d="M55 34.2c0-9.4 0-18.9 0-28.3 0-1.2 0-2.3 0-3.5 0-1.4-0.4-1.8-1.8-1.8 -0.9 0-1.8 0-2.7 0 -1.5 0-1.8 0.4-1.8 1.9 0 5.3 0 10.5 0 15.8 0 5.3 0 10.6 0 15.9l0 0c0 1.6 0.3 1.9 1.9 1.9 0.8 0 1.7 0 2.5 0C54.7 36.1 55 35.7 55 34.2z" />
    <path d="M1.1 33.8c2.9 0 5.8 0 8.8 0l7.9 0c2.3 0 2.2 0.2 2.2-2.3 0-1-0.2-1.2-1.2-1.2 -3.8 0-7.7 0-11.5 0 -2.1 0-4.1 0-6.2 0 -0.7 0-1.1 0.3-1.1 1.1 0 0.4 0 0.9 0 1.3C0 33.7 0.2 33.8 1.1 33.8z" />
    <path d="M20 3.4c0-0.9-0.2-1.1-1.1-1.1 -2.9 0-5.8 0-8.8 0l0 0h-8C0 2.3 0 2.3 0 4.4c0 1.3 0.1 1.4 1.4 1.4 5.4 0 10.9 0 16.3 0C20.2 5.8 20 5.9 20 3.4z" />
    <path d="M1.1 28.3c1.7 0 3.5 0 5.2 0 2.6 0 2.4 0.3 2.4-2.4 0-1.1-0.1-1.2-1.3-1.2 -1.2 0-2.5 0-3.8 0l0-6.3c0-3.2 0-6.3 0-9.5 0-0.9-0.2-1.1-1.1-1.1 -0.4 0-0.9 0-1.3 0C0.2 7.8 0 8 0 9c0 6.1 0 12.2 0 18.3C0 28 0.3 28.3 1.1 28.3z" />
    <path d="M53.3 54.5c0.1-1.7 0.2-3.3 0.1-5 0-0.5-0.4-0.7-0.9-0.7h-2.7c-2 0-2 0-2 2 0 1.3 0 1.3 1.3 1.3 0.2 0 0.6 0.2 0.7 0.3 0.2 1-0.1 2-0.8 2.7 -0.6 0.5-1.5 0.7-2.3 0.3 -0.9-0.4-1.4-1.3-1.4-2.3 0-1.4 0-2.8 0-4.3h0c0-1.5 0-3.1 0-4.6 0-1 0.8-1.9 1.8-2 1.2-0.2 2.4 0.1 2.6 1 0.3 1.1 0.9 1.2 1.8 1.1 0.4 0 0.8 0 1.1 0 0.6 0 0.8-0.3 0.7-0.8 -0.1-1.3-0.7-2.5-1.7-3.2 -1.3-0.9-2.8-1.3-4.4-1.2 -2.8-0.1-5.2 2.2-5.2 5 0 0.1 0 0.2 0 0.2 0 3 0 6.1 0 9.1 0 0.6 0.1 1.3 0.3 1.9 0.8 2.3 2.6 3.5 5.4 3.5h0.4C50.8 59 53 57.1 53.3 54.5z" />
    <path d="M129.2 9.3c0 0.1 0 0.3 0 0.4 0 2.8 0 5.6 0 8.4l0 0c0 3.1-0.1 6.2 0 9.3 0.2 4 3 7.4 6.8 8.6 1.3 0.3 2.5 0.4 3.8 0.4 5.3 0 9.6-4.2 9.7-9.5 0-0.2 0-0.4 0-0.6 0.1-0.8-0.5-1.5-1.3-1.6 -0.1 0-0.1 0-0.2 0 -1 0-2 0-3 0 -1.3 0-1.8 0.5-1.9 1.8 0 1.2-0.6 2.3-1.5 3 -1.1 0.9-2.6 1.1-3.9 0.5 -1.6-0.7-2.6-2.2-2.5-3.9 0-5.5 0-10.9 0-16.4 0-2.2 1.7-4 3.9-4 0.3 0 0.6 0 0.9 0.1 1.8 0.3 3.1 1.9 3.1 3.7 0.1 1.4 0.6 1.9 2 1.9 0.9 0 1.9 0 2.8 0 0.8 0.1 1.5-0.5 1.6-1.4 0-0.1 0-0.2 0-0.2 0.1-2.9-1.2-5.7-3.5-7.5 -1.4-1.1-3-1.7-4.7-2h-4.1C132.8 0.8 129.3 4.6 129.2 9.3z" />
    <path d="M167 0.5c-3.2 0-6.4 0-9.7 0 -1.5 0-3.1 0-4.6 0 -1.2 0-1.6 0.5-1.6 1.6 0 0.9 0 1.7 0 2.6 0 1.2 0.4 1.6 1.6 1.6 1 0 2 0 3.1 0 0.7 0 0.9 0.2 0.9 0.9 0 4 0 8 0 12 0 5 0 10 0 15 0 1.3 0.4 1.7 1.7 1.7 1 0 1.9 0 2.9 0 1.4 0 1.8-0.4 1.8-1.8 0-4.5 0-9 0-13.4l0 0c0-4.5 0-9.1 0-13.6 0-0.6 0.2-0.8 0.8-0.8 1.2 0 2.3 0 3.5 0 1.1 0 1.6-0.5 1.6-1.6 0-0.8 0-1.6 0-2.3C168.8 0.9 168.4 0.5 167 0.5z" />
    <path d="M111.3 34.3c0.2 1.1 1.2 1.8 2.3 1.6 1.2 0 2.4 0 3.5 0 1.3 0 1.6-0.4 1.2-1.6 -1.6-4.6-3.1-9.1-4.7-13.7 0 0 0 0 0-0.1 -0.2-0.4 0-0.9 0.4-1.1 1.9-1.2 3.1-3.3 3.1-5.5 0-2.1 0-4.2 0-6.4 0-2.2-1-4.2-2.8-5.4 -1.4-1-3.1-1.5-4.8-1.5 -3.2 0-6.5 0-9.7 0 -1.3 0-1.8 0.5-1.8 1.8 0 5.3 0 10.6 0 15.9 0 5.3 0 10.6 0 15.9 0 1.2 0.4 1.6 1.6 1.6 1.2 0 2.4 0 3.6 0s1.6-0.4 1.6-1.6c0-3.7 0-7.3 0-11 0-1 0.2-1.3 1-1.4 0 0 0.1 0 0.1 0 0.8-0.2 1.5 0.3 1.7 1C108.8 26.7 110.1 30.5 111.3 34.3 111.3 34.3 111.3 34.3 111.3 34.3zM108 15.7c-0.9 0.1-1.8 0-2.6 0 -0.1 0-0.1 0-0.2 0 -0.5-0.1-0.8-0.5-0.8-1 0-2.7 0-5.5 0-8.2 0-0.1 0-0.1 0-0.2 0.1-0.4 0.5-0.7 0.9-0.7 0.9 0 1.7 0 2.6 0 0 0 0.1 0 0.1 0 1.5 0 2.7 1.3 2.7 2.8 0 0.7 0 1.5 0 2.2l0 0c0 0.7 0 1.4 0 2.2 0 0 0 0 0 0C110.6 14.4 109.5 15.7 108 15.7z" />
    <path d="M67.8 36.5c3.8 0.1 7.2-2.2 8.6-5.7 1.5-3.4 1.6-7.3 0.4-10.9 -0.7-2.1-2.3-3.8-4.5-4.4 -1.1-0.3-2.3-0.6-3.4-0.7 -1.2-0.2-2.5-0.3-3.6-0.6 -1.1-0.2-1.9-1.3-1.8-2.5 0-0.8 0-1.6 0-2.3 -0.1-1.9 1.3-3.5 3.1-3.7 0.2 0 0.4 0 0.5 0C69 5.7 70.6 7.1 70.7 9c0 0.1 0 0.2 0 0.3 -0.1 0.8 0.5 1.5 1.3 1.5 0.1 0 0.2 0 0.3 0 1 0 2.1 0 3.1 0 0.8 0.1 1.5-0.6 1.6-1.4 0-0.1 0-0.2 0-0.3 0-2.2-0.7-4.4-2.2-6.1 -1.8-2.1-4.6-3.3-7.4-3 -0.7 0-1.2 0-1.7 0l0 0c-3.6 0-6.7 2.4-7.7 5.8 -0.2 0.9-0.3 1.8-0.3 2.7 -0.2 2.2-0.1 4.5 0.5 6.6 0.6 2.4 2.5 4.2 5 4.6 1.6 0.3 3.3 0.3 4.9 0.6 2.1 0.3 3.6 2.2 3.3 4.3 0 0.8-0.1 1.5-0.1 2.3 0 1.6-1.2 3-2.9 3.3 -2 0.4-3.7-0.3-4.3-1.9 -0.2-0.6-0.3-1.1-0.3-1.7C63.7 25.6 63.1 25 62 25c-1 0-1.9 0-2.9 0 -0.9-0.1-1.8 0.5-1.9 1.5 0 0.2 0 0.3 0 0.5 -0.1 5.3 4.2 9.6 9.4 9.6C67 36.5 67.4 36.5 67.8 36.5z" />
    <path d="M112.6 58.6c2.1 0 4.1 0 6.2 0 2.3 0 2.1 0.1 2.1-2.2 0-1-0.2-1.2-1.2-1.2 -1.5 0-2.9 0-4.4 0 -0.5 0-0.7-0.1-0.7-0.7 0-1.2 0-2.4 0-3.6 0-0.5 0.1-0.7 0.7-0.7 0.7 0 1.4 0 2.1 0 2.3 0 2.1 0.2 2.1-2.2 0-1-0.2-1.1-1.1-1.1 -1.2 0-2.4 0-3.7 0 0-1.3 0-2.5 0-3.7l0 0c0-0.2 0.4-0.4 0.6-0.4 1.2 0 2.4 0 3.5 0 2.3 0 2.2 0.1 2.1-2.2 0-1-0.1-1.1-1.1-1.1 -2.4 0-4.8 0-7.2 0 -1 0-1.2 0.1-1.2 1.1 0 5.6 0 11.2 0 16.8C111.3 58.5 111.5 58.6 112.6 58.6z" />
    <path d="M11 28.2c2.2 0 4.4 0 6.6 0 2.5 0 2.3 0.2 2.3-2.4 0-1-0.2-1.2-1.2-1.2 -1.6 0-3.2 0-4.8 0 -0.5 0-0.7-0.1-0.7-0.6 0-1.3 0-2.7 0-4 0-0.5 0.2-0.7 0.7-0.6 0.7 0 1.4 0 2.2 0 2.6 0 2.4 0.2 2.3-2.4 0-1-0.1-1.1-1.1-1.1 -1.1 0-2.3 0-3.4 0 -0.3 0-0.7 0-0.7-0.5 0-1.3 0-2.6 0-3.9 0.1 0 0.2-0.1 0.3-0.1l0 0c1.3 0 2.7 0 4 0 2.5 0 2.4 0.3 2.4-2.5 0-0.9-0.2-1.1-1.1-1.1 -2.6 0-5.3 0-7.9 0 -1 0-1.2 0.2-1.2 1.2 0 6 0 11.9 0 17.9C9.8 28.1 10 28.2 11 28.2z" />
    <path d="M36.5 0.6c-3.2 0-6.4 0-9.6 0 -1.5 0-2.1 0.5-2.1 2.1 0 5.2 0 10.5 0 15.7l0 0c0 5.3 0 10.6 0 15.8 0 1.4 0.5 1.8 1.8 1.8 3.5 0 7 0 10.5-0.1 1 0 2-0.2 2.9-0.6 3.7-1.6 6.1-5.2 5.9-9.2 0-5.4 0-10.8 0-16.2 0-0.2 0-0.3 0-0.5C45.8 4.5 41.6 0.5 36.5 0.6zM39.6 25.4c0 0.2 0 0.4 0 0.6 -0.2 2.4-2.3 4.2-4.6 4 -1.1 0-2.2 0-3.3 0 -0.7 0-0.9-0.3-0.9-0.9 0-3.6 0-7.2 0-10.8l0 0c0-3.5 0-7 0-10.5 0-1.2 0.1-1.3 1.4-1.3 1.2 0 2.3 0 3.5 0 0.2 0 0.3 0 0.5 0 2.1 0.2 3.7 2 3.5 4.1C39.6 15.5 39.6 20.4 39.6 25.4z" />
  </svg>
);

export default LogotypeSVG;
