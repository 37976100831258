// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../Button';

// Styles
import './_logoBox.scss';

const LogoBox = ({
  slug,
  theme,
  title,
  logo,
  menuPdf,
  overwriteClasse
}) => {
  const logoBoxClasse = `logo-list__item col-12 col-md-6 col-lg-4 item__${theme}`;
  return (
    <article key={slug} data-slug={slug} className={overwriteClasse ? overwriteClasse : logoBoxClasse}>
      <div>
        {!!logo && (
          <img src={logo} alt="" />
        )}
        {!!logo == '' && (
          <h2>{title}</h2>
        )}
        <Button outline={false} href={menuPdf} className="space--top" target="_blank">
          Télécharger le menu
        </Button>
      </div>
    </article>
  );
};

LogoBox.propTypes = {
  slug: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  overwriteClasse: PropTypes.string,
  logo: PropTypes.string,
  menuPdf: PropTypes.string,
};

LogoBox.defaultProps = {
  slug: '',
  logo: '',
  menuPdf: '',
  theme: 'light',
  overwriteClasse: ''
};

export default LogoBox;
