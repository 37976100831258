// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// i18n
import i18n from '../../i18n';

// Svg
import QgSVG from '../../assets/svg/qg';

// Styles
import './_map-marker.scss';

const MapMarker = ({ infowindow, isOpen, onclick }) => {
  let markerClasses = 'marker';
  if (isOpen) markerClasses += ' marker--open';

  return (
    <div className="map-marker">
      {isOpen && !!infowindow.title && (
        <div className="infowindow">
          <h2 className="infowindow__title h4">{infowindow.title}</h2>
          <div
            className="infowindow__text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: infowindow.text }}
          />
          <button
            aria-label={i18n.t('close')}
            className="infowindow__button"
            onClick={onclick}
            type="button"
          />
        </div>
      )}
      <button className={markerClasses} onClick={onclick} type="button">
        <QgSVG />
      </button>
    </div>
  );
};

MapMarker.propTypes = {
  infowindow: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onclick: PropTypes.func,
};

MapMarker.defaultProps = {
  infowindow: PropTypes.shape({
    title: '',
    text: '',
  }),
  isOpen: false,
  onclick: null,
};

export default MapMarker;
