// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Accordion from '../components/Accordion';

// Utils
import { getFaq } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';

class Faq extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      metatags: {},
      questions: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getFaq();

    if (this._isMounted && !!data) {
      const { metatags, questions } = data;

      this.setState(prevState => ({
        ...prevState,
        metatags,
        questions,
      }));
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { metatags, questions } = this.state;

    return (
      <Layout className="faq-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h1 className="hero__title">{i18n.t('faq').title}</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-page__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                {questions.map(({ answer, id, question }) => {
                  return (
                    <Accordion key={id} id={id} title={question}>
                      {renderHtml(answer)}
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Faq.contextType = AppContext;

export default withTranslation()(Faq);
