// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// styles
import './_button.scss';

const Button = ({
  children,
  className,
  disabled,
  href,
  light,
  onClick,
  outline,
  type,
  target,
  to,
}) => {
  let btnClasses = 'btn';
  if (light) btnClasses += ' btn-light';
  if (outline) btnClasses += ' btn-outline';
  if (className) btnClasses += ` ${className}`;

  const aRel = target === '_blank' ? 'noopener noreferrer' : '';

  return (
    <>
      {onClick && (
        <button
          disabled={disabled}
          onClick={onClick}
          // eslint-disable-next-line react/button-has-type
          type={type}
          className={btnClasses}
        >
          {children}
        </button>
      )}
      {to && (
        <Link to={to} className={btnClasses}>
          {children}
        </Link>
      )}
      {href && (
        <a href={href} target={target} rel={aRel} className={btnClasses}>
          {children}
        </a>
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  light: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  type: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Button.defaultProps = {
  className: '',
  disabled: false,
  href: null,
  light: false,
  onClick: null,
  outline: true,
  target: null,
  type: 'button',
  to: null,
};

export default Button;
