// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Utils
import { settings } from '../../config';

const YoastSeo = ({ tags }) => {
  const mergedTags = { ...settings.metatags, ...tags };

  return (
    <Helmet>
      <html lang={mergedTags.language} />
      <title>{mergedTags.title}</title>
      <meta name="description" content={mergedTags.description} />
      {/* <meta name="robots" content={mergedTags.robots} /> */}
      <meta property="og:locale" content={mergedTags.language} />
      <meta property="og:type" content={mergedTags.type} />
      <meta property="og:title" content={mergedTags.opengraph.title} />
      <meta property="og:description" content={mergedTags.description} />
      <meta property="og:url" content={window.location.origin} />
      <meta property="og:site_name" content={mergedTags.site_name} />
      <meta property="og:image" content={mergedTags.opengraph.image.url} />
      <meta
        property="og:image:secure_url"
        content={mergedTags.opengraph.image.url}
      />
      <meta name="twitter:card" content={mergedTags.twitter.card_type} />
      <meta name="twitter:title" content={mergedTags.twitter.title} />
      <meta
        name="twitter:description"
        content={mergedTags.twitter.description}
      />
      <meta name="twitter:image" content={mergedTags.twitter.image.url} />
    </Helmet>
  );
};

YoastSeo.propTypes = {
  tags: PropTypes.shape({
    description: PropTypes.string,
    language: PropTypes.string,
    opengraph: PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        image: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
    robots: PropTypes.string,
    title: PropTypes.string,
    twitter: PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        image: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
    type: PropTypes.string,
  }).isRequired,
};

export default YoastSeo;
