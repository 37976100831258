// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Hero from '../components/Hero';
import JobCardList from '../components/JobCardList';
import Layout from '../components/Layout';
import SearchInput from '../components/SearchInput';
import TextImage from '../components/TextImage';

// Utils
import { getJobs } from '../utils/wordpressApi';
import filters from '../utils/filters';

class Job extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      description: '',
      filtered: [],
      jobs: [],
      metatags: {},
      pathname,
      search: '',
      thumbnail: {
        sizes: { large: '', xl: '' },
        title: '',
      },
      title: '',
    };

    this.filterList = this.filterList.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getJobs();

    if (this._isMounted && !!data) {
      const { description, jobs, metatags, thumbnail, title } = data;

      this.setState(prevState => ({
        ...prevState,
        description,
        jobs,
        metatags,
        thumbnail,
        title,
      }));
    }

    // Set list without filters
    this.filterList();

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Filter jobs list
   */
  filterList(value = '') {
    const { jobs } = this.state;
    let filtered = [];

    if (!!jobs) {
      if (!value || value === '') {
        filtered = [...jobs];
      } else {
        filtered = filters('title', value, jobs);
      }
    }

    this.setState({
      filtered,
      search: value,
    });

    return filtered;
  }

  render() {
    const {
      description,
      filtered,
      jobs,
      metatags,
      pathname,
      search,
      thumbnail,
      title,
    } = this.state;

    return (
      <Layout className="job-page" metatags={metatags}>
        <Hero title={i18n.t('jobs')} />

        {!!thumbnail.sizes.large && (
          <TextImage
            image={thumbnail}
            reversed
            text={description}
            title={title}
          />
        )}

        {!!jobs && (
          <>
            <section className="job-page__list">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-11 offset-lg-1">
                    <div className="card-filters">
                      <div className="card-filters__container">
                        <div className="row">
                          <div className="card-filters__filter card-filters__filter--category col-md-10 offset-md-1 col-lg-4 offset-lg-0">
                            <SearchInput
                              id="filter-search"
                              name="filter-search"
                              value={search}
                              handleChange={e => {
                                this.filterList(e.target.value);
                              }}
                              handleClick={() => {
                                this.filterList(search);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <JobCardList items={filtered} pathname={pathname} />
          </>
        )}

        {!jobs && (
          <section className="no-jobs">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-11 offset-lg-1">
                  <p className="h4">{i18n.t('no_job_offers')}</p>
                </div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    );
  }
}

Job.contextType = AppContext;

export default withTranslation()(Job);
