// Vendors
import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({
  checked,
  disabled,
  error,
  id,
  label,
  name,
  onChange,
  value,
}) => {
  return (
    <div className={`form-group custom-control custom-checkbox${error ? ' is-invalid' : ''}`}>
      <input
        checked={checked}
        className="custom-control-input"
        id={id}
        disabled={disabled}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <label
        className="custom-control-label"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckBox.defaultProps = {
  error: false,
  checked: false,
  disabled: false,
  value: 0,
};

export default CheckBox;