// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import PromoForm from '../components/PromoForm';
import Layout from '../components/Layout';
import Button from '../components/Button';

// Utils
import { getPromotion } from '../utils/wordpressApi';
import getLocalizedPath from '../utils/getLocalizedPath';
import { renderHtml } from '../utils/renderHtml';
import { convertDate } from '../utils/date';

class PromotionSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      beginDate: null,
      content: '',
      endDate: null,
      metatags: {},
      redirect: null,
      slug: props.match.params.slug,
      thumbnail: {
        title: '',
        sizes: {
          landscapeSm: '',
          landscapeWideLg: '',
        },
      },
      title: '',
      afficher_formulaire: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getPromotion(slug);

    if (this._isMounted) {
      if (data) {
        const {
          beginDate,
          content,
          endDate,
          metatags,
          thumbnail,
          title,
          afficher_formulaire,
        } = data;

        this.setState(prevState => ({
          ...prevState,
          beginDate: beginDate ? convertDate(beginDate, 'dd MMMM yyyy') : null,
          content,
          endDate: endDate ? convertDate(endDate, 'dd MMMM yyyy') : null,
          metatags,
          thumbnail,
          title,
          afficher_formulaire,
        }));
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      beginDate,
      content,
      endDate,
      metatags,
      redirect,
      thumbnail,
      title,
      afficher_formulaire,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    let dateLabel = '';

    if (!!beginDate && !!endDate) {
      dateLabel = `${i18n.t('offers').applicable} ${
        i18n.t('offers').from
      } ${beginDate} ${i18n.t('offers').to} ${endDate}`;
    }

    if (!beginDate && !!endDate) {
      dateLabel = `${i18n.t('offers').applicable} ${
        i18n.t('offers').until
      } ${endDate}`;
    }

    return (
      <Layout className="promotion-page" metatags={metatags}>
        <section className="promotion-page__content">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-12">
                {!!dateLabel && <p className="date">{dateLabel}</p>}
                <h1 className="hero__title">{title}</h1>
                <div className="promotion-page__content--text gutenberg-wrapper">
                  {renderHtml(content)}
                </div>
                <div className="promotion-page__content--cta">
                  <Button to={getLocalizedPath('promotion')}>
                    {i18n.t('back')}
                  </Button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5">
                {!!thumbnail.sizes.landscapeSm && (
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={thumbnail.sizes.landscapeWideLg}
                    />
                    <img
                      src={thumbnail.sizes.landscapeSm}
                      alt={thumbnail.title}
                      title={thumbnail.title}
                    />
                  </picture>
                )}
              </div>
              {afficher_formulaire === true &&
                <div className="col-12">
                  <h2 className="text-center">Formulaire contact</h2>
                  <PromoForm
                    promoName = {title}
                  />
                </div>
              }
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

PromotionSingle.contextType = AppContext;

export default withTranslation()(PromotionSingle);
