// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import LogoBox from '../components/LogoBox';

// Utils
import { getRestaurants } from '../utils/wordpressApi';
import filters from '../utils/filters';
import { shuffle } from '../utils/tools';

class Restaurant extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      categories: [],
      chefs: { title: '', seeMore: false },
      filtered: [],
      introduction: '',
      isMobile: false,
      metatags: {},
      pathname,
      restaurants: [],
      rooms: {
        title: '',
        images: [],
      },
      seasonalOffers: {
        title: '',
        items: [],
        seeMore: false,
      },
    };

    this.filterList = this.filterList.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getRestaurants();

    if (this._isMounted && !!data) {
      const {
        metatags,
        restaurants,
        seasonalOffers,
        logo_page_menu,
        settings: {
          chefsTitle,
          description: introduction,
          roomsTitle,
          roomsImages,
          seasonalOffersTitle,
        },
      } = data;

      this.setState(prevState => ({
        ...prevState,
        chefs: {
          title: chefsTitle,
          seeMore: false,
        },
        introduction,
        metatags,
        logo_page_menu,
        restaurants: shuffle(restaurants),
        rooms: {
          title: roomsTitle,
          images: roomsImages,
        },
        seasonalOffers: {
          title: seasonalOffersTitle,
          items: seasonalOffers,
          seeMore: false,
        },
      }));

      /**
       * Get distinct restaurant categories
       */

      const categories = restaurants
        .filter(
          (restaurant, index, self) =>
            index ===
            self.findIndex(t => t.category.slug === restaurant.category.slug)
        )
        .map(restaurant => {
          return restaurant.category;
        });

      this.setState({
        categories,
        filtered: this.filterList(null, null),
      });

      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Filter list
   */
  filterList(type, slug) {
    const { restaurants } = this.state;
    let filtered = [];

    if (!type || !slug) {
      filtered = [...restaurants];
    } else {
      filtered = filters('category', slug, restaurants);
    }

    this.setState({
      filtered,
    });

    return filtered;
  }

  handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  render() {
    const {
      filtered,
      isMobile,
      metatags,
      pathname,
      seasonalOffers,
      logo_page_menu,
    } = this.state;

    const {
      options: {
        links: { menu: menuLink },
      },
    } = this.context;

    return (
      <Layout className="restaurant-listing" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="hero__title col-12">
                <h1>Nos menus</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="restaurants-list">
          <div className="container">
            <div className="card-list row">
              {filtered.map(({ title, caption, category, image, slug, logo, logo_page_menu, menuPdf }) => {
                {logo_page_menu}
                {logo}
                if(logo_page_menu){
                  return (
                    <LogoBox
                      key={slug}
                      title={title}
                      caption={caption}
                      image={{
                        sizes: {
                          medium: image.sizes.medium,
                          small: image.sizes.medium,
                        },
                        title: image.title,
                      }}
                      subtitle={category.name}
                      slug={slug}
                      to={`${pathname}/${slug}`}
                      hover={i18n.t('see_more')}
                      logo={logo_page_menu}
                      menuPdf={menuPdf}
                    />
                  );
                }
                else if(logo){
                  return (
                    <LogoBox
                      key={slug}
                      title={title}
                      caption={caption}
                      image={{
                        sizes: {
                          medium: image.sizes.medium,
                          small: image.sizes.medium,
                        },
                        title: image.title,
                      }}
                      subtitle={category.name}
                      slug={slug}
                      to={`${pathname}/${slug}`}
                      hover={i18n.t('see_more')}
                      logo={logo}
                      menuPdf={menuPdf}
                    />
                  );
                }
              })}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

Restaurant.contextType = AppContext;

export default withTranslation()(Restaurant);
