import fr from 'date-fns/locale/fr';
import { format, parseISO } from 'date-fns';

/**
 * Format date
 */
export function convertDate(date, output) {
  if (!date || date === '') {
    return null;
  }
  return format(parseISO(date), output ? output : 'dd MMMM yyyy', {
    locale: fr,
  });
}
