// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// i18n
import i18n from '../../i18n';

// Components
import Button from '../Button';

// Utils
import { convertDate } from '../../utils/date';

// Styles
import './_job-card.scss';

const JobCard = ({ date, excerpt, pathname, title, slug }) => {
  return (
    <div className="job-card row">
      <div className="job-card__title col-12 col-md-10 col-lg-3 offset-lg-1">
        <h3>{title}</h3>
      </div>
      <div className="job-card__content col-12 col-md-10 col-lg-6 col-xl-5 offset-lg-1">
        <p className="job-card__content--date">
          {convertDate(date, 'dd.MM.yyyy')}
        </p>
        {/* <p className="job-card__content--excerpt">{excerpt}</p> */}
        <Button className="btn-outline" to={`${pathname}/${slug}`}>
          {i18n.t('see_more')}
        </Button>
      </div>
    </div>
  );
};

JobCard.propTypes = {
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default JobCard;
