export default function scrollToElement(id, smooth = true, block = 'start') {
  const element = document.getElementById(id);

  if (element) {
    if (smooth) {
      element.scrollIntoView({ behavior: 'smooth', block });
    } else {
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = element.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      window.scrollTo(0, offset);
    }
    return true;
  }

  return false;
}
