// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

// Styles
import './_text-center.scss';

const TextCenter = ({ button, surtitle, text, title }) => {
  return (
    <section className="text-center">
      <div className="container">
        <div className="row">
          <div className="text-center__content col-11 col-md-9 col-lg-7 col-xl-6 mx-auto">
            <h2 className="text-center__title">
              {!!surtitle && (
                <span className="text-center__surtitle">{surtitle}</span>
              )}
              {title}
            </h2>
            {!!text && <div className="text-center__text">{text}</div>}
            {!!button.text && (
              <Button
                text={button.text}
                className={button.className}
                href={button.href}
                onClick={button.onClick}
                outline={button.outline}
                target={button.target}
                to={button.to}
              >
                {button.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TextCenter.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  surtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

TextCenter.defaultProps = {
  button: {
    text: '',
    className: '',
    href: null,
    onClick: null,
    outline: true,
    target: null,
    to: null,
  },
  surtitle: '',
  text: '',
};

export default TextCenter;
