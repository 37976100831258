// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Button from '../Button';

// Styles
import './_cardCat.scss';

const CardCat = ({
  caption,
  hover,
  href,
  image,
  price,
  slug,
  subtitle,
  theme,
  title,
  title2,
  to,
  logo,
  cacher,
  overwriteClasse,
  myCat,
  btnBook,
  btnPDF,
  galerie_photo_thematique,
  texte_thematique
}) => {
  const cardClasse = `col-12 col-md-6 col-lg-6 item__${theme}`;

  if(cacher){
    return "";
  }
  else{

    const items = galerie_photo_thematique || []
    return (
      <article key={slug} data-slug={slug} className={overwriteClasse ? overwriteClasse : cardClasse}>
        <a href={href} className="block cat-block">
          <h3 className="item__title">{title} - {title2}</h3>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="slider-cont autoHeight">
                <Slider
                  dots
                  arrows={false}
                  infinite
                  speed={1000}
                  autoplay
                  slidesToShow={1}
                  slidesToScroll={1}
                  pauseOnHover
                  swipeToSlide
                  swipe
                  touchThreshold={100}
                  >
                  {items.map(item => {
                    return <img src={item} />
                  })}
                </Slider>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div dangerouslySetInnerHTML={{__html: texte_thematique}}></div>
            </div>
          </div>
          <div className="row bottom-btn">
            {!!btnPDF && (
              <div className="col-sm">
                <Button className="btn-outline" href={btnPDF}>
                  Menu
                </Button>
              </div>
            )}
            {!!btnBook && (
              <div className="col-sm">
                <Button className="btn-outline" href={btnBook}>
                  Réserver
                </Button>
              </div>
            )}
            <div className="col-sm">
              <Button className="btn-outline" href={to}>
                Savoir +
              </Button>
            </div>
          </div>
        </a>
      </article>
    );
  }
};

CardCat.propTypes = {
  caption: PropTypes.string,
  hover: PropTypes.string.isRequired,
  href: PropTypes.string,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.string,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overwriteClasse: PropTypes.string,
  logo: PropTypes.string,
  galerie_photo_thematique: PropTypes.array,
  texte_thematique: PropTypes.string,
};

CardCat.defaultProps = {
  caption: '',
  href: null,
  price: '',
  slug: '',
  logo: '',
  subtitle: '',
  theme: 'light',
  to: null,
  overwriteClasse: '',
  texte_thematique: ''
};

export default CardCat;
