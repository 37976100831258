// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './_text-three-columns.scss';

// Utils
import { renderHtml } from '../../utils/renderHtml';

const TextThreeColumns = ({ columns }) => {
  return (
    <section className="text-three-columns">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className="row">
              {columns.map(({ text, title }, index) => (
                <div
                  key={`column_${index}`}
                  className="text-three-columns__column col-md-4"
                >
                  <h3 className="column__title">{title}</h3>
                  <div className="column__text">{renderHtml(text)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TextThreeColumns.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TextThreeColumns;
