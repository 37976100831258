// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Layout from '../components/Layout';
import Card from '../components/Card';
import CardCat from '../components/CardCat';
import CtaButtons from '../components/CtaButtons';
import Carousel from '../components/Carousel';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import SectionTitle from '../components/SectionTitle';
import Slider from 'react-slick';
import TextImage from '../components/TextImage';

// Utils
import { getRestaurantCat } from '../utils/wordpressApi';
import { stripHtml } from '../utils/renderHtml';
import filters from '../utils/filters';
import { slugify, shuffle } from '../utils/tools';

class Restaurant extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      categories: [],
      chefs: { title: '', seeMore: false },
      filtered: [],
      introduction: '',
      isMobile: false,
      metatags: {},
      pathname,
      restaurants: [],
      logo: '',
      catslug: props.match.params.slug,
      cat_texte_image: [],
      rooms: {
        title: '',
        images: [],
      },
      seasonalOffers: {
        title: '',
        items: [],
        seeMore: false,
      }
    };

    this.filterList = this.filterList.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Get anchor
    const {
      location: { state: locationState },
    } = this.props;

    let anchor = null;

    if (
      !!locationState &&
      Object.prototype.hasOwnProperty.call(locationState, 'anchor')
    ) {
      const { anchor: locationAnchor } = locationState;
      anchor = locationAnchor;
    }

    const { catslug } = this.state;

    // Fetch Wordpress Data
    const data = await getRestaurantCat(catslug);

    if (this._isMounted && !!data) {
      const {
        metatags,
        restaurants,
        seasonalOffers,
        settings: {
          chefsTitle,
          description: introduction,
          roomsTitle,
          roomsImages,
          seasonalOffersTitle,
          cat_title,
          cat_subtitle,
          cat_description,
          cat_adresse,
          cat_galerie_photo,
          cat_texte_bouton_reserver,
          cat_lien_bouton_reserver,
          cat_texte_image,
        },
      } = data;
      console.log(JSON.stringify(cat_texte_image));

      this.setState(prevState => ({
        ...prevState,
        chefs: {
          title: chefsTitle,
          seeMore: false,
        },
        introduction,
        metatags,
        restaurants: shuffle(restaurants),
        rooms: {
          title: roomsTitle,
          images: roomsImages,
        },
        seasonalOffers: {
          title: seasonalOffersTitle,
          items: seasonalOffers,
          seeMore: false,
        },
        cat_title,
        cat_subtitle,
        cat_description,
        cat_adresse,
        cat_galerie_photo,
        cat_texte_bouton_reserver,
        cat_lien_bouton_reserver,
        cat_texte_image
      }));
      console.log(JSON.stringify(cat_texte_image));

      /**
       * Get distinct restaurant categories
       */

      const categories = restaurants
        .filter(
          (restaurant, index, self) =>
            index ===
            self.findIndex(t => t.category.slug === restaurant.category.slug)
        )
        .map(restaurant => {
          return restaurant.category;
        });

      this.setState({
        categories,
        filtered: this.filterList(null, null),
      });

      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Filter list
   */
  filterList(type, slug) {
    const { restaurants } = this.state;
    let filtered = [];

    if (!type || !slug) {
      filtered = [...restaurants];
    } else {
      filtered = filters('category', slug, restaurants);
    }

    filtered = restaurants;

    this.setState({
      filtered,
    });

    return filtered;
  }

  handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  render() {
    const {
      categories,
      chefs,
      filtered,
      introduction,
      isMobile,
      metatags,
      pathname,
      restaurants,
      rooms,
      logo,
      galerie_photo_thematique,
      repeteur_thematique,
      catslug,
      seasonalOffers,
      cat_title,
      cat_subtitle,
      cat_description,
      cat_adresse,
      cat_galerie_photo,
      cat_texte_bouton_reserver,
      cat_lien_bouton_reserver,
      cat_texte_image
    } = this.state;

    const {
      options: {
        links: { menu: menuLink },
      },
    } = this.context;

    let seasonalOffersList = seasonalOffers.items;

    if (isMobile && !seasonalOffers.seeMore) {
      seasonalOffersList = seasonalOffers.items.slice(0, 3);
    }

    let chefsList = [];

    if (isMobile && !chefs.seeMore) {
      chefsList = restaurants
        .slice(0, 3)
        .map(({ chef: { image, name }, slug, title }) => {
          return { image, name, slug, title };
        });
    } else {
      chefsList = restaurants.map(({ chef: { image, name }, slug, title }) => {
        return { image, name, slug, title };
      });
    }
    console.log(JSON.stringify(cat_texte_image));

    return (
      <Layout className="restaurant-listing" metatags={metatags}>
        {!!cat_title && (
          <section className="category__entete">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1">
                  <h1>{cat_title}</h1>
                  <h2>{cat_subtitle}</h2>
                  <div dangerouslySetInnerHTML={{__html: cat_description}} />
                  <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6">
                      <p><b>{cat_adresse}</b></p>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      {!!cat_lien_bouton_reserver && !!cat_texte_bouton_reserver && (
                        <p><a href={cat_lien_bouton_reserver} className="btn">{cat_texte_bouton_reserver}</a></p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-5 offset-xl-1">
                  <div className="slider-cont">
                    <Slider
                      dots
                      arrows={true}
                      infinite
                      speed={1000}
                      autoplay
                      slidesToShow={1}
                      slidesToScroll={1}
                      pauseOnHover
                      swipeToSlide
                      swipe
                      touchThreshold={100}
                      responsive={[
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          },
                        },
                      ]}
                    >
                      {cat_galerie_photo.map(item => {
                        return <img src={item} />
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {!cat_title && (
          <section className="hero">
            <div className="container">
              <div className="row">
                <div className="hero__title col-12">
                  <h1 className="text-center">{catslug}</h1>
                </div>
              </div>
            </div>
          </section>
        )}

        {!!cat_title && (
          <section className="restaurants-list">
            <div className="container">
              {/* <CtaButtons buttons={['custom']} customLink={['/restaurants']} customLabel="Toutes les catégories" /> */}
              <div className="card-list row">
                {filtered.map(({ title, caption, category, image, slug, logo, chef, book_link, menu_pdf, repeteur_thematique, myCat, galerie_photo_thematique, texte_thematique, menu_pdf_thematique }) => {
                  {category.map(catSingle => {
                    if(repeteur_thematique){
                      {repeteur_thematique.map(item => {
                        myCat = item.categorie_actuelle;
                        if(catSingle.slug == myCat.slug){
                          galerie_photo_thematique = item.galerie_photo_thematique;
                          texte_thematique = item.texte_thematique;
                          menu_pdf_thematique = item.menu_pdf_thematique;
                        }
                      })}
                    }
                  })}
                  return (
                    <CardCat
                      key={slug}
                      title={title}
                      title2={chef.name}
                      caption={caption}
                      image={{
                        sizes: {
                          medium: image.sizes.medium,
                          small: image.sizes.medium,
                        },
                        title: image.title,
                      }}
                      subtitle={category.name}
                      slug={slug}
                      to={`/restaurants/${slug}`}
                      hover={i18n.t('see_more')}
                      logo={logo}
                      btnBook={book_link}
                      btnPDF={menu_pdf_thematique}
                      texte_thematique={texte_thematique}
                      galerie_photo_thematique={galerie_photo_thematique}
                    />
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {!cat_title && (
          <section className="restaurants-list">
            <div className="container">
              <CtaButtons buttons={['custom']} customLink={['/restaurants']} customLabel="Toutes les catégories" />
              <div className="card-list row">
                {filtered.map(({ title, caption, category, image, slug, logo }) => {
                  return (
                    <Card
                      key={slug}
                      title={title}
                      caption={caption}
                      image={{
                        sizes: {
                          medium: image.sizes.medium,
                          small: image.sizes.medium,
                        },
                        title: image.title,
                      }}
                      subtitle={category.name}
                      slug={slug}
                      to={`/restaurants/${slug}`}
                      hover={i18n.t('see_more')}
                      logo={logo}
                    />
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {!cat_title && (
          <CtaButtons buttons={['order']} />
        )}

        {!!cat_texte_image && (
          <section className="text-image__cat">
            <div className="container">
              <hr/>
            </div>
            {cat_texte_image.map((item, index) => (
              <div key={item}>
                {!!index % 2 === 0 && (
                  <TextImage
                    image={{
                      sizes: {
                        desktop: item.image,
                      },
                    }}
                    reversed
                    text={item.texte}
                    title={item.titre}
                  />
                )}
                {!index % 2 === 0 && (
                  <TextImage
                    image={{
                      sizes: {
                        desktop: item.image,
                      },
                    }}
                    text={item.texte}
                    title={item.titre}
                  />
                )}
              </div>
            ))}
          </section>
        )}

        {!!seasonalOffersList && !!seasonalOffersList.length && (
          <section className="seasonal-offers">
            <div className="seasonal-offers__wrapper">
              <SectionTitle
                centered
                surtitle={i18n.t('seasonal_offers')}
                title={seasonalOffers.title}
              />

              <div className="container">
                <div className="seasonal-offers__list row">
                  {seasonalOffersList.map(
                    ({ image, name, price, restaurant }) => {
                      if (!name) {
                        return null;
                      }
                      return (
                        <Card
                          hover={i18n.t('see_more')}
                          key={`${slugify(name)}${restaurant}_seasonal_offer`}
                          image={{
                            sizes: {
                              medium: image.sizes.landscapeWideMd,
                              small: image.sizes.landscapeWideSm,
                            },
                            title: image.title,
                          }}
                          to={{
                            pathname: `${pathname}/${restaurant}`,
                            state: { anchor: 'menu-anchor' },
                          }}
                          price={`- ${price}`}
                          slug={restaurant}
                          title={name}
                        />
                      );
                    }
                  )}
                </div>
                {isMobile && !seasonalOffers.seeMore && (
                  <Button
                    className="seasonal-offers__see-more btn-outline d-block mx-auto"
                    onClick={() => {
                      this.setState(prevState => ({
                        ...prevState,
                        seasonalOffers: {
                          ...prevState.seasonalOffers,
                          seeMore: true,
                        },
                      }));
                    }}
                  >
                    {i18n.t('see_all')}
                  </Button>
                )}
              </div>
            </div>
          </section>
        )}

        <SectionTitle
          surtitle={i18n.t('craftsmen').our_craftsmen}
          title={chefs.title}
          centered
          classes="chefs-title"
        />

        <section className="chefs-list">
          <div className="chefs-list__wrapper">
            <div className="container">
              <div className="row">
                {chefsList.map(({ image, name, slug, title }) => {
                  if (!name) {
                    return null;
                  }
                  return (
                    <Card
                      key={`${slug}_chef`}
                      theme="dark"
                      title={name}
                      image={{
                        sizes: {
                          medium: image.sizes.landscapeWideMd,
                          small: image.sizes.landscapeWideSm,
                        },
                        title: image.title,
                      }}
                      subtitle={`${i18n.t('chef').title} ${title}`}
                      slug={slug}
                      to={{
                        pathname: `${pathname}/${slug}`,
                        state: { anchor: 'chef-anchor' },
                      }}
                      hover={i18n.t('see_more')}
                    />
                  );
                })}
              </div>
              {isMobile && !chefs.seeMore && (
                <Button
                  className="chefs-list__see-more btn-outline btn-light d-block mx-auto"
                  onClick={() => {
                    this.setState(prevState => ({
                      ...prevState,
                      chefs: {
                        ...prevState.chefs,
                        seeMore: true,
                      },
                    }));
                  }}
                >
                  {i18n.t('see_all_chefs')}
                </Button>
              )}
            </div>
          </div>
        </section>

        <CtaButtons buttons={['rent']} />

        <section className="rooms-slider">
          <Carousel items={rooms.images} />
        </section>
      </Layout>
    );
  }
}

Restaurant.contextType = AppContext;

export default withTranslation()(Restaurant);
