// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// i18n
import i18n from '../../i18n';

// Components
import JobCard from './JobCard';

// Styles
import './_job-card-list.scss';

const JobCardList = ({ items, pathname }) => {
  return (
    <section className="job-card-list">
      <div className="container">
        {(() => {
          if (items.length > 0) {
            return items.map(item => (
              <JobCard
                date={item.date}
                excerpt={item.excerpt}
                key={item.id}
                pathname={pathname}
                slug={item.slug}
                title={item.title}
              />
            ));
          }
          return (
            <div className="row">
              <div className="col-12 col-lg-11 offset-lg-1">
                <h3>{i18n.t('result').sorry},</h3>
                <p className="h4">{i18n.t('result').no_result}</p>
              </div>
            </div>
          );
        })()}
      </div>
    </section>
  );
};

JobCardList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default JobCardList;
