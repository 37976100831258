import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const AuthButton = ({ children, login, mode }) => {
  const { loginWithRedirect, logout } = useAuth0();

  const toggleAuth = () => {
    if (login) {
      loginWithRedirect({
        mode,
        returnTo: window.location.origin,
        callbackURL: window.location.origin,
      });
    } else {
      logout({ returnTo: window.location.origin });
    }
  };

  return (
    <button className="site-header__link" type="button" onClick={toggleAuth}>
      {children}
    </button>
  );
};

AuthButton.propTypes = {
  children: PropTypes.node.isRequired,
  login: PropTypes.bool,
  mode: PropTypes.string,
};

AuthButton.defaultProps = {
  login: true,
  mode: 'login',
};

export default AuthButton;
