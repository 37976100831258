// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Card from '../components/Card';
import CtaButtons from '../components/CtaButtons';
import Layout from '../components/Layout';
import SectionTitle from '../components/SectionTitle';

// Utils
import { getArtisan } from '../utils/wordpressApi';
import { renderHtml } from '../utils/renderHtml';
import { slugify } from '../utils/tools';

class ArtisanSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      artisan: {
        description: '',
        image: {
          sizes: {
            landscapeWideMd: '',
            landscapeWideSm: '',
            portraitLg: '',
          },
          title: '',
        },
        name: '',
      },
      contact: {
        address: '',
        hours: '',
      },
      description: '',
      email: '',
      metatags: {},
      products: [],
      redirect: null,
      slug: props.match.params.slug,
      thumbnail: {
        sizes: {
          landscapeWideSm: '',
          xl: '',
        },
        title: '',
      },
      title: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    // Fetch Wordpress Data
    const data = await getArtisan(slug);

    if (this._isMounted) {
      if (data) {
        const {
          address,
          artisan,
          description,
          email,
          hours,
          metatags,
          products,
          thumbnail,
          title,
        } = data;

        this.setState(prevState => ({
          ...prevState,
          artisan,
          contact: {
            address,
            hours,
          },
          description,
          email,
          metatags,
          products,
          thumbnail,
          title,
        }));
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      artisan,
      contact,
      description,
      email,
      metatags,
      products,
      redirect,
      thumbnail,
      title,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    return (
      <Layout className="artisan-single" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="hero__text col-lg-4 offset-lg-1">
                <span className="hero__subtitle">{i18n.t('the_market')}</span>
                <h1 className="hero__title">{title}</h1>
                {!!description && (
                  <div className="hero__introduction">
                    {renderHtml(description)}
                  </div>
                )}
              </div>
              {!!thumbnail.sizes.landscapeWideSm && (
                <div className="hero__thumbnail">
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={thumbnail.sizes.xl}
                    />
                    <img
                      src={thumbnail.sizes.landscapeWideSm}
                      alt={thumbnail.title}
                    />
                  </picture>
                </div>
              )}
            </div>
          </div>
        </section>

        {!!products && !!products.length && (
          <>
            <section className="products">
              <div className="products__wrapper">
                <SectionTitle
                  centered
                  surtitle={i18n.t('local_offers')}
                  title={i18n.t('featured_products')}
                />

                <div className="container">
                  <div className="products__list row">
                    {products.map(
                      ({ productDescription, image, name, price }) => {
                        if (!name) {
                          return null;
                        }
                        return (
                          <Card
                            hover={i18n.t('see_more')}
                            key={`${slugify(name)}_product`}
                            image={{
                              sizes: {
                                medium: image.sizes.landscapeWideMd,
                                small: image.sizes.landscapeWideSm,
                              },
                              title: image.title,
                            }}
                            price={`- ${price}`}
                            title={name}
                            caption={productDescription}
                          />
                        );
                      }
                    )}
                  </div>
                </div>

                {/* <CtaButtons buttons={['grocery']} /> */}
              </div>
            </section>
          </>
        )}

        {/* !!products && !products.length && <CtaButtons buttons={['grocery']} /> */}

        {!!artisan.name && (
          <section className="artisan">
            <div id="artisan-anchor" />
            <div className="container">
              <div className="row">
                <div className="artisan__image col-lg-5 offset-lg-1">
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet={artisan.image.sizes.portraitLg}
                    />
                    <source
                      media="(min-width: 768px)"
                      srcSet={artisan.image.sizes.landscapeWideMd}
                    />
                    <img
                      src={artisan.image.sizes.landscapeWideSm}
                      alt={artisan.image.title}
                    />
                  </picture>
                </div>
                <div className="artisan__description col-lg-4 offset-lg-1">
                  <div className="artisan__text">
                    <h2 className="artisan__title">
                      <span className="artisan__surtitle">
                        {i18n.t('artisan').meet}
                      </span>
                      {artisan.name}
                    </h2>
                    {renderHtml(artisan.description)}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <h2 className="contact__title title-underline">
                  {i18n.t('contact')}
                </h2>
              </div>
              <div className="contact__location col-lg-3 offset-lg-1">
                <h3 className="contact__subtitle">
                  {renderHtml(contact.address)}
                </h3>
                {!!email && (
                  <a className="contact__email" href={`mailto:${email}`}>
                    {email}
                  </a>
                )}
              </div>
              <div className="contact__opening col-lg-3 offset-lg-1">
                <h3 className="contact__subtitle">
                  {i18n.t('restaurant').hours}
                </h3>
                {renderHtml(contact.hours)}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

ArtisanSingle.contextType = AppContext;

export default withTranslation()(ArtisanSingle);
