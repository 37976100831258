import i18n from '../i18n';
import routes from '../routes';

/*
 * Get Localized Path
 */
export default function getLocalizedPath(key = null) {
  if (key !== null) {
    let route = routes.filter(({ key: routeKey }) => routeKey === key);

    if (route.length > 0) {
      route = route[0];
      const localizedPath = route.paths[i18n.language];

      if (localizedPath) {
        return localizedPath;
      }
    }
  }

  return null;
}
