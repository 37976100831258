// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

// i18n
import i18n from '../../i18n';

// Utils
import getLocalizedPath from '../../utils/getLocalizedPath';

// Components
import Button from '../Button';
import NewsletterForm from '../NewsletterForm';

// Assets
import FacebookSVG from '../../assets/svg/facebook';
import ImediaSVG from '../../assets/svg/imedia';
import InstagramSVG from '../../assets/svg/instagram';
import LogotypeSVG from '../../assets/svg/logotype';
import YoutubeSVG from '../../assets/svg/youtube';
import PinterestSVG from '../../assets/svg/pinterest';

// Styles
import './_footer.scss';

const Footer = ({ options: { links, schema, social }, pathname }) => {
  const handleLinkPhone = event => {
    gtag('event', 'Téléphone', {
      event_category: 'phone',
      event_action: 'click',
      event_label: 'click-phone',
    });
  };

  const nav = [
    {
      id: 'about',
      label: i18n.t('about'),
      to: getLocalizedPath('about'),
    },
    {
      id: 'catering',
      label: i18n.t('catering').title,
      to: getLocalizedPath('catering'),
    },
    {
      id: 'terms-and-conditions',
      label: i18n.t('terms_and_conditions'),
      to: getLocalizedPath('terms-and-conditions'),
    },
    {
      id: 'terms-and-conditions-cards',
      label: "Conditions d'utilisation des cartes",
      to: getLocalizedPath('card-conditions'),
    },
    // {
    //   id: 'client-service',
    //   label: i18n.t('client_service'),
    //   to: getLocalizedPath('client-service'),
    // },
    {
      id: 'privacy-policy',
      label: i18n.t('privacy_policy'),
      to: getLocalizedPath('privacy-policy'),
    },
    {
      id: 'jobs',
      label: i18n.t('jobs'),
      to: getLocalizedPath('job'),
    },
    {
      id: 'blog',
      label: i18n.t('blog').title,
      to: getLocalizedPath('blog'),
    },
    {
      id: 'faq',
      label: i18n.t('faq').acronym,
      to: getLocalizedPath('faq'),
    },
  ];

  return (
    <footer className="site-footer">
      <div className="site-footer__inner-wrapper">
        <div className="newsletter-form">
          <div className="container">
            <h2>{i18n.t('newsletter').title}</h2>
            <MailchimpSubscribe
              url={process.env.MAILCHIMP_FORM_URL}
              render={({ subscribe, status, message }) => (
                <NewsletterForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-6 col-md-4 order-md-1 col-lg-3 col-xl-4">
              <nav className="site-footer__nav">
                <ul>
                  {nav.map(({ id, label, to }) => {
                    if (pathname === to) {
                      return (
                        <li key={id}>
                          <a href={to}>{label}</a>
                        </li>
                      );
                    }
                    return (
                      <li key={id}>
                        <Link to={to}>{label}</Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>

            <div className="col-6 col-md-4 order-md-2 col-lg-3">
              <div className="site-footer__coords">
                <h4 className="title">{i18n.t('footer').join_us}</h4>
                <p>
                  <a
                    href={links.itinerary}
                    title={i18n.t('itinerary')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {schema.streetAddress}
                    <br />
                    {schema.addressLocality}
                  </a>
                  <br />
                  {i18n.t('footer').email} :&nbsp;
                  <a href="mailto:info@ledistrictgourmet.ca">
                    info@ledistrictgourmet.ca
                  </a>
                  <br />
                  {i18n.t('footer').phone} :&nbsp;
                  <a href={`tel:${schema.telephone}`} onClick={handleLinkPhone}>
                    {schema.telephone}{' '}
                  </a>
                </p>
              </div>

              <div className="site-footer__hours">
                <h4 className="title">{i18n.t('footer').opening}</h4>
                {schema.openingHours.map((opening, key) => (
                  <p key={`opening_${key}`}>
                    {opening.days.map((day, index) => (
                      <span key={`day_${index}`}>
                        {i18n.t('days')[day.toLowerCase()]}
                        {index < opening.days.length - 1 ? ', ' : ''}
                      </span>
                    ))}{' '}
                    : {opening.opens} - {opening.closes}
                  </p>
                ))}
              </div>

              <div className="site-footer__socials d-md-none">
                <a
                  href={social.facebook}
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookSVG />
                </a>
                <a
                  href={social.instagram}
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramSVG />
                </a>
              </div>
            </div>

            <div className="col-6 order-md-4 d-lg-flex align-items-lg-center justify-content-lg-center col-lg-4 order-lg-3 col-xl-3">
              <div className="site-footer__contest">
                {pathname === getLocalizedPath('contest') && (
                  <Button
                    className="btn-outline btn-light"
                    href={getLocalizedPath('contest')}
                  >
                    {i18n.t('our_contests')}
                  </Button>
                )}
                {pathname !== getLocalizedPath('contest') && (
                  <Button
                    className="btn-outline btn-light"
                    to={getLocalizedPath('contest')}
                  >
                    {i18n.t('our_contests')}
                  </Button>
                )}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center col-6 align-items-md-end col-md-4 order-md-3 justify-content-lg-between col-lg-2 order-lg-4">
              <div className="site-footer__brand">
                <Link to={getLocalizedPath('home')}>
                  <LogotypeSVG />
                </Link>
              </div>

              <div className="site-footer__socials d-none d-md-block">
                <a
                  href={social.facebook}
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookSVG />
                </a>
                <a
                  href={social.instagram}
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramSVG />
                </a>
                <a
                  href={social.youtube}
                  title="Youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeSVG />
                </a>
                <a
                  href={social.pinterest}
                  title="Pinterest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PinterestSVG />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  options: PropTypes.objectOf(PropTypes.object),
  pathname: PropTypes.string,
};

Footer.defaultProps = {
  options: {},
  pathname: '',
};

export default Footer;
