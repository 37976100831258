import React from 'react';
import ReactDOM from 'react-dom';

// Providers
import { I18nextProvider } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';

// i18n
import i18n from './i18n';

// Router
import AppRouter from './router';

// Import styles
import './assets/scss/app.scss';

// Import assets
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.AUTH0_AUDIENCE}
    >
      <AppRouter />
    </Auth0Provider>
  </I18nextProvider>,
  document.getElementById('wrapper')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
