// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Button from '../components/Button';
import Layout from '../components/Layout';
import EventForm from '../components/EventForm';

// Utils
import { getEvent } from '../utils/wordpressApi';
import { getPlacesLeft } from '../utils/eventbriteApi';
import getLocalizedPath from '../utils/getLocalizedPath';
import { renderHtml } from '../utils/renderHtml';
import { convertDate } from '../utils/date';

class EventSingle extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      beginDate: '',
      beginTime: '',
      content: '',
      endDate: '',
      endTime: '',
      eventbrite: {
        link: '',
        id: null,
        placesLeft: null,
        showPlacesLeft: false,
      },
      inscriptionBeginDate: null,
      inscriptionEndDate: null,
      limitTicketPerPerson: null,
      maximumInscriptionCount: null,
      metatags: {},
      minimumInscriptionCount: null,
      price: '',
      redirect: null,
      slug: props.match.params.slug,
      thumbnail: {
        title: '',
        sizes: {
          landscapeSm: '',
          landscapeWideLg: '',
        },
      },
      title: '',
      courriel_formulaire: '',
      show_index_artisan: null,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    const { slug } = this.state;

    const data = await getEvent(slug);

    if (this._isMounted) {
      if (data) {
        const {
          beginDate,
          beginTime,
          content,
          endDate,
          endTime,
          eventbrite: {
            link: eventbriteLink,
            id: eventbriteId,
            showPlacesLeft,
          },
          inscriptionBeginDate,
          inscriptionEndDate,
          limitTicketPerPerson,
          maximumInscriptionCount,
          metatags,
          minimumInscriptionCount,
          price,
          thumbnail,
          title,
          courriel_formulaire,
          show_index_artisan,
        } = data;

        let placesLeft = null;
        if (showPlacesLeft && !!eventbriteId) {
          placesLeft = await getPlacesLeft(eventbriteId);
        }

        this.setState(prevState => ({
          ...prevState,
          beginDate,
          beginTime,
          content,
          endDate,
          endTime,
          eventbrite: {
            link: eventbriteLink,
            id: eventbriteId,
            placesLeft,
            showPlacesLeft,
          },
          inscriptionBeginDate,
          inscriptionEndDate,
          limitTicketPerPerson,
          maximumInscriptionCount,
          metatags,
          minimumInscriptionCount,
          price,
          thumbnail,
          title,
          courriel_formulaire,
          show_index_artisan,
        }));
      } else {
        // Page isn't published or slug does not exist, redirect to parent
        const {
          match: { path },
        } = this.props;

        this.setState({
          redirect: path.replace('/:slug', ''),
        });
      }
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      beginDate,
      beginTime,
      content,
      endDate,
      endTime,
      eventbrite,
      inscriptionBeginDate,
      inscriptionEndDate,
      limitTicketPerPerson,
      maximumInscriptionCount,
      metatags,
      minimumInscriptionCount,
      price,
      redirect,
      thumbnail,
      title,
      courriel_formulaire,
      show_index_artisan,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} status={301} />;
    }

    return (
      <Layout className="event-page" metatags={metatags}>
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <Button to={getLocalizedPath('event')}>
                  {i18n.t('back-event')}
                </Button>
                <br/><br/>
                <h1 className="hero__title">{title}</h1>
                <br/>
                {!!eventbrite.link && (
                  <div className="menu-hero__cta d-none justify-content-end d-lg-flex">
                    <Button
                      outline={false}
                      href={eventbrite.link}
                      target="_blank"
                    >
                      {i18n.t('events').subscribe}
                    </Button>
                  </div>
                )}

                <div className="event-page__content--date">
                  <p className="date">
                    {convertDate(beginDate, 'EEEE')}
                    <br />
                    {convertDate(beginDate, 'dd MMMM yyyy')}
                  </p>
                  {!!endDate && endDate !== beginDate && (
                    <p className="date">
                      {convertDate(endDate, 'EEEE')}
                      <br />
                      {convertDate(endDate, 'dd MMMM yyyy')}
                    </p>
                  )}
                  <p className="time">
                    {beginTime} - {endTime}
                  </p>
                  <p className="price">{price}</p>
                  {!!eventbrite.showPlacesLeft && !!eventbrite.placesLeft && (
                    <p className="places-left">
                      Nombre places restantes: {eventbrite.placesLeft}
                    </p>
                  )}
                  {!!inscriptionBeginDate && !!inscriptionEndDate && (
                    <p className="inscription-period">
                      {i18n.t('events').inscription_begin_end_dates_1}{' '}
                      {convertDate(inscriptionBeginDate, 'dd MMMM')}{' '}
                      {i18n.t('events').inscription_begin_end_dates_2}{' '}
                      {convertDate(inscriptionEndDate, 'dd MMMM')}
                    </p>
                  )}
                  {!!inscriptionBeginDate && !inscriptionEndDate && (
                    <p className="inscription-period">
                      {i18n.t('events').inscription_begin_date}{' '}
                      {convertDate(inscriptionBeginDate, 'dd MMMM')}
                    </p>
                  )}
                  {!inscriptionBeginDate && !!inscriptionEndDate && (
                    <p className="inscription-period">
                      {i18n.t('events').inscription_end_date}{' '}
                      {convertDate(inscriptionEndDate, 'dd MMMM')}
                    </p>
                  )}
                  {!!minimumInscriptionCount && (
                    <p className="minimum">
                      {i18n.t('events').minimum_inscription}:{' '}
                      {minimumInscriptionCount}
                    </p>
                  )}
                  {!!maximumInscriptionCount && (
                    <p className="maximum">
                      {i18n.t('events').maximum_inscription}:{' '}
                      {maximumInscriptionCount}
                    </p>
                  )}
                  {!!limitTicketPerPerson && (
                    <p className="limit">
                      {i18n.t('events').limit_ticket_per_person}:{' '}
                      {limitTicketPerPerson}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                {!!thumbnail.sizes.landscapeSm && (
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={thumbnail.sizes.landscapeWideLg}
                    />
                    <img
                      src={thumbnail.sizes.landscapeSm}
                      alt={thumbnail.title}
                      title={thumbnail.title}
                    />
                  </picture>
                )}
              </div>
              <div className="col-12">
                <div className="event-page__content--text gutenberg-wrapper">
                  {renderHtml(content)}
                </div>
              </div>
              {!!title && !!courriel_formulaire && (
                <div className="col-12 spacer--top">
                  <EventForm
                    eventName = {title}
                    email = {courriel_formulaire}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

EventSingle.contextType = AppContext;

export default withTranslation()(EventSingle);
