// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

// Styles
import './_section-title.scss';

const SectionTitle = ({
  button,
  centered,
  classes,
  introduction,
  light,
  surtitle,
  title,
  full,
}) => {
  let sectionClasses = 'section-title';
  if (light) sectionClasses += ' section-title--light';
  if (classes) sectionClasses += ` ${classes}`;

  let colClasses = centered ? 'col-12' : 'col-lg-6';
  if (full) colClasses = 'col-lg-7';

  let titleClasses = 'section-title__title';
  if (centered) titleClasses += ' section-title__title--centered';
  if (full) titleClasses += ' full';

  let buttonContainerClasses = 'd-none d-lg-block';
  if (centered) buttonContainerClasses += ' col-lg-12 text-center mb-0';
  else buttonContainerClasses += ' col-lg-6 text-right';
  if (full) buttonContainerClasses = 'col-lg-5 text-right';

  let buttonClasses = button.className;
  if (surtitle) buttonClasses += ' with-margin-top';

  return (
    <section className={sectionClasses}>
      <div className="section-title__container">
        <div className="row">
          <div className={colClasses}>
            <h2 className={titleClasses}>
              {!!surtitle && (
                <span className="section-title__surtitle">{surtitle}</span>
              )}
              {title}
            </h2>
            {!!introduction && (
              <p className="section-title__introduction">{introduction}</p>
            )}
          </div>

          {!!button.text && (
            <div className={buttonContainerClasses}>
              <Button
                text={button.text}
                className={buttonClasses}
                href={button.href}
                light={button.light}
                onClick={button.onClick}
                outline={button.outline}
                target={button.target}
                to={button.to}
              >
                {button.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SectionTitle.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    light: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  centered: PropTypes.bool,
  full: PropTypes.bool,
  classes: PropTypes.string,
  introduction: PropTypes.string,
  light: PropTypes.bool,
  surtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SectionTitle.defaultProps = {
  button: {
    text: '',
    className: '',
    href: null,
    light: false,
    onClick: null,
    outline: true,
    target: null,
    to: null,
  },
  centered: false,
  full: false,
  classes: '',
  introduction: '',
  light: false,
  surtitle: '',
};

export default SectionTitle;
