// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './_card.scss';

const Card = ({
  caption,
  hover,
  href,
  image,
  price,
  slug,
  subtitle,
  theme,
  title,
  to,
  logo,
  cacher,
  overwriteClasse,
}) => {
  const cardClasse = `card-list__item col-12 col-md-6 col-lg-4 item__${theme}`;
  if(cacher){  
    return "";
  }
  else{
    return (
      <article key={slug} data-slug={slug} className={overwriteClasse ? overwriteClasse : cardClasse}>
        {!!slug && !!href && (
          <a href={href} className={!!logo && ("hover-logo")}>
            <div className="top">
              <picture data-content={hover}>
                <source media="(min-width: 768px)" srcSet={image.sizes.medium} />
                <img src={image.sizes.small} alt={image.title} />
              </picture>
              {!!logo && (
                <div className="hover-block">
                  <img src={logo} alt="" />
                </div>
              )}
            </div>
            <h3 className="item__title">{title}</h3>
            {!!subtitle && <p className="item__subtitle">{subtitle}</p>}
            {!!caption && <p className="item__description">{caption}</p>}
            {!!price && <p className="item__price">{price}</p>}
          </a>
        )}
        {!!slug && !href && (
          <Link to={to} className={!!logo && ("hover-logo")}>
            <div className="top">
              <picture data-content={hover}>
                <source media="(min-width: 768px)" srcSet={image.sizes.medium} />
                <img src={image.sizes.small} alt={image.title} />
              </picture>
              {!!logo && (
                <div className="hover-block">
                  <img src={logo} alt="" />
                </div>
              )}
            </div>
            <h3 className="item__title">{title}</h3>
            {!!subtitle && <p className="item__subtitle">{subtitle}</p>}
            {!!caption && <p className="item__description">{caption}</p>}
            {!!price && <p className="item__price">{price}</p>}
          </Link>
        )}
        {!slug && (
          <div>
            <picture data-content={hover}>
              <source media="(min-width: 768px)" srcSet={image.sizes.medium} />
              <img src={image.sizes.small} alt={image.title} />
            </picture>
            <h3 className="item__title">{title}</h3>
            {!!subtitle && <p className="item__subtitle">{subtitle}</p>}
            {!!caption && <p className="item__description">{caption}</p>}
            {!!price && <p className="item__price">{price}</p>}
          </div>
        )}
      </article>
    );  
  }
};

Card.propTypes = {
  caption: PropTypes.string,
  hover: PropTypes.string.isRequired,
  href: PropTypes.string,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.string,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overwriteClasse: PropTypes.string,
  logo: PropTypes.string,
};

Card.defaultProps = {
  caption: '',
  href: null,
  price: '',
  slug: '',
  logo: '',
  subtitle: '',
  theme: 'light',
  to: null,
  overwriteClasse: ''
};

export default Card;
